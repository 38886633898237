<div class="upload pb-15" *ngIf="documentList">
	<ng-container *ngFor="let data of documentList">
		<!-- ------------------------ Gst Certificate ------------------------ -->
		<p class="fs-16 fw-500  pt-10 pb-5" *ngIf="data.file_id == 1">
			{{data.document}}
			<span *ngIf="data.is_mandatory"
				[ngClass]="customEventFromParent && !gstCertificateFile && !documentReset ? 'error-msg' : ''">*</span>
		</p>
		<ion-row class="mb-5 id-proof" *ngIf="data.file_id == 1">
			<ion-col>
				<div class="upload-btn">
					<div *ngIf="gstCertificateFile" class="file-info h-40 fs-16 fw-500" [title]="gstCertificateFile">
						<span>{{
							gstCertificateFile }}</span>
					</div>
					<div *ngIf="!gstCertificateFile" class="file-info like-placeholder fs-16 fw-500">Upload
						{{data.document}}
					</div>
					<button *ngIf="(!gstCertificateFile || gstCertificateFile === null ) && !isDisable"
						class="file-upload-button clear-btn pointer fs-14 fw-600">
						<span> Upload</span>
						<input type="file" #gstCertificateFile (click)="clearProofFileInput(gstCertificateFile)"
							(change)="onCertificateSelect(gstCertificateFile,data)" />
					</button>
					<div *ngIf="gstCertificateFile && !isDisable">
						<button *ngIf="gstCertificateFile && from != 'documentRegister'" (click)="viewCert()"
							class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
							<span>View</span>
						</button>
						<button *ngIf="gstCertificateFile && !isSellerApproval && !data.is_mandatory"
							(click)="removeCert(data)" class="w-112 h-45 clear-btn pointer fs-14 fw-600">
							<span> Remove</span>
						</button>

						<button *ngIf="data.is_mandatory && gstCertificateFile && from != 'documentRegister'"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Update</span>
							<input type="file" #gstCertificateFile (click)="clearProofFileInput(gstCertificateFile)"
								(change)="onCertificateSelect(gstCertificateFile,data,true)" />
						</button>
					</div>


				</div>
			</ion-col>
		</ion-row>
		<span class="error-text" *ngIf="customEventFromParent && !gstCertificateFile && !documentReset">Please upload
			{{data.document}} file</span>


		<!-- ------------------------ Aadhar card file ------------------------ -->

		<ng-container *ngIf="data.file_id == 6">
			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}}
				<span *ngIf="data.is_mandatory">*</span>
			</p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="customerAadharFile" class="file-info h-40 fs-16 fw-500"
							[title]="customerAadharFile">
							<span>{{customerAadharFile}}</span>
						</div>
						<div *ngIf="!customerAadharFile" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}</div>
						<button *ngIf="(!customerAadharFile || customerAadharFile === null) && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #customerAadharFile
								(click)="clearFileInput(customerAadharFile, 'customerAadharFile')"
								(change)="onFileSelect( data ,$event.target, 'customerAadharFile')" />
						</button>
						<div *ngIf="customerAadharFile  && !isDisable ">
							<button *ngIf="customerAadharFile && from != 'documentRegister'"
								(click)="viewFile('customerAadharFile')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="customerAadharFile && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('customerAadharFile',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600">
								<span> Remove</span>
							</button>

							<button *ngIf="data.is_mandatory && customerAadharFile && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> update</span>
								<input type="file" #customerAadharFile
									(click)="clearFileInput(customerAadharFile, 'customerAadharFile')"
									(change)="onFileSelect( data ,$event.target, 'customerAadharFile',true)" />
							</button>
						</div>

					</div>
				</ion-col>
			</ion-row>
		</ng-container>

		<!-- ------------------------ Pan card file ------------------------ -->

		<ng-container *ngIf="data.file_id == 11">
			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}}
				<span *ngIf="data.is_mandatory">*</span>
			</p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="customerPanCardFile" class="file-info h-40 fs-16 fw-500"
							[title]="customerPanCardFile">
							<span>{{customerPanCardFile}}</span>
						</div>
						<div *ngIf="!customerPanCardFile" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}</div>
						<button *ngIf="(!customerPanCardFile || customerPanCardFile === null) && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #customerPanCardFile
								(click)="clearFileInput(customerPanCardFile, 'customerPanCardFile')"
								(change)="onFileSelect( data ,$event.target, 'customerPanCardFile')" />
						</button>
						<div *ngIf="customerPanCardFile  && !isDisable ">
							<button *ngIf="customerPanCardFile && from != 'documentRegister'"
								(click)="viewFile('customerPanCardFile')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="customerPanCardFile && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('customerPanCardFile',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600">
								<span> Remove</span>
							</button>

							<button *ngIf="data.is_mandatory && customerPanCardFile && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> update</span>
								<input type="file" #customerPanCardFile
									(click)="clearFileInput(customerPanCardFile, 'customerPanCardFile')"
									(change)="onFileSelect( data ,$event.target, 'customerPanCardFile',true)" />
							</button>
						</div>

					</div>
				</ion-col>
			</ion-row>
		</ng-container>


		<!-- -------------------------- Director Id Proof aadharFile multiple ------------------------ -->

		<div *ngIf="data.file_id == 5">
			<div class="flex space-between mv-20">
				<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}}<span *ngIf="data.is_mandatory"
						[ngClass]="customEventFromParent && !aadharFile && !documentReset ? 'error-msg' : ''">*</span>
				</p>
				<button class="file-upload-button clear-btn pointer fs-14 fw-600 mr-5" *ngIf=" !isDisable">
					<span> Upload</span>
					<input type="file" id="files" name="files" #aadharFiles
						(change)="onFileSelect( data ,$event.target, 'aadharFiles')" multiple />
				</button>

			</div>

			<ion-row class="mb-5 id-proof" *ngIf="aadharFile && aadharFile.length">
				<ng-container *ngFor="let file of aadharFile; let i = index">
					<ion-col>
						<div class="upload-btn">
							<div *ngIf="!aadharFile" class="file-info like-placeholder fs-16 fw-500">Upload
								{{data.document}}
							</div>

							<div class="file-info h-40 fs-16 fw-500">
								({{i+1}}). <span>{{ file.name }} </span>
							</div>
							<div *ngIf="!isDisable">
								<button (click)="viewFile('aadharFiles',i,true,file.file)"
									*ngIf=" from != 'documentRegister'"
									class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
									<span> View</span>
								</button>

								<button (click)="removeFile('aadharFiles',file,i)"
									class="w-112 h-45 clear-btn pointer fs-14 fw-600">
									<span> Remove</span>
								</button>
							</div>


						</div>
						<div class="border-bottom"></div>
					</ion-col>
				</ng-container>
			</ion-row>
			<span class="error-text" *ngIf="customEventFromParent && !aadharFile.length && !documentReset">Please upload
				{{data.document}}</span>
		</div>





		<!-- -------------------------- Pictures of Office (Inside and Outside) multiple ------------------------ -->

		<div *ngIf="data.file_id == 2">
			<div class="flex space-between mv-20">
				<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}}<span *ngIf="data.is_mandatory"
						[ngClass]="customEventFromParent && !officeFile && !documentReset ? 'error-msg' : ''">*</span>
				</p>
				<button class="file-upload-button clear-btn pointer fs-14 fw-600 mr-5" *ngIf=" !isDisable">
					<span> Upload</span>
					<input type="file" id="files" name="files" #officeFiles
						(change)="onFileSelect( data ,$event.target, 'officeFiles')" multiple />
				</button>

			</div>
			<ion-row class="mb-5 id-proof" *ngIf="officeFile && officeFile.length">
				<ng-container *ngFor="let file of officeFile; let i = index">
					<ion-col>
						<div class="upload-btn">
							<div *ngIf="!officeFile" class="file-info like-placeholder fs-16 fw-500">Upload
								{{data.document}}</div>

							<div class="file-info h-40 fs-16 fw-500">
								({{i+1}}). <span>{{ file.name }} </span>
							</div>

							<div *ngIf=" !isDisable">

								<button (click)="viewFile('officeFiles',i,true,file.file)"
									*ngIf=" from != 'documentRegister'"
									class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
									<span> View</span>
								</button>

								<button (click)="removeFile('officeFiles',file,i)"
									class="w-112 h-45 clear-btn pointer fs-14 fw-600">
									<span> Remove</span>
								</button>
							</div>

						</div>
						<div class="border-bottom"></div>
					</ion-col>
				</ng-container>
			</ion-row>
			<span class="error-text" *ngIf="customEventFromParent && !officeFile.length && !documentReset">Please upload
				{{data.document}}</span>
		</div>


		<!--  ----------------------------------- All Director's Pan card  multiple ------------------------>
		<div *ngIf="data.file_id == 4">
			<div class="flex space-between mv-20">
				<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}}<span *ngIf="data.is_mandatory"
						[ngClass]="customEventFromParent && !directorPanCard && !documentReset ? 'error-msg' : ''">*</span>
				</p>
				<button class="file-upload-button clear-btn pointer fs-14 fw-600 mr-5" *ngIf=" !isDisable">
					<span> Upload</span>
					<input type="file" id="files" name="files" #directorPan
						(change)="onFileSelect( data ,$event.target, 'directorPanCard')" multiple />
				</button>

			</div>
			<ion-row class="mb-5 id-proof" *ngIf="directorPanCard && directorPanCard.length">
				<ng-container *ngFor="let file of directorPanCard; let i = index">
					<ion-col>
						<div class="upload-btn">
							<div *ngIf="!directorPanCard" class="file-info like-placeholder fs-16 fw-500">Upload
								{{data.document}}</div>

							<div class="file-info h-40 fs-16 fw-500">
								({{i+1}}). <span>{{ file.name }} </span>
							</div>

							<div *ngIf=" !isDisable">

								<button (click)="viewFile('directorPanCard',i,true,file.file)"
									*ngIf="from != 'documentRegister'"
									class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
									<span> View</span>
								</button>

								<button (click)="removeFile('directorPanCard',file,i)"
									class="w-112 h-45 clear-btn pointer fs-14 fw-600">
									<span> Remove</span>
								</button>
							</div>



						</div>
						<div class="border-bottom"></div>
					</ion-col>
				</ng-container>
			</ion-row>
			<span class="error-text" *ngIf="customEventFromParent && !directorPanCard.length && !documentReset">Please
				upload {{data.document}}</span>
		</div>


		<!-- -------------------------- MSME / Udyam Certifiacte ------------------------ -->
		<ng-container *ngIf="data.file_id == 9">
			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}}
				<span *ngIf="data.is_mandatory">*</span>
			</p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="msmeCertificate" class="file-info h-40 fs-16 fw-500" [title]="msmeCertificate">
							<span>{{msmeCertificate}}</span>
						</div>
						<div *ngIf="!msmeCertificate" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}</div>
						<button *ngIf="(!msmeCertificate || msmeCertificate === null) && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #msmeCertificate
								(click)="clearFileInput(msmeCertificate, 'msmeCertificate')"
								(change)="onFileSelect( data ,$event.target, 'msmeCertificate')" />
						</button>
						<div *ngIf="msmeCertificate  && !isDisable">
							<button *ngIf="msmeCertificate && from != 'documentRegister'"
								(click)="viewFile('msmeCertificate')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="msmeCertificate && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('msmeCertificate',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600">
								<span> Remove</span>
							</button>

							<button *ngIf="data.is_mandatory && msmeCertificate && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> update</span>
								<input type="file" #msmeCertificate
									(click)="clearFileInput(msmeCertificate, 'msmeCertificate')"
									(change)="onFileSelect( data ,$event.target, 'msmeCertificate',true)" />
							</button>
						</div>

					</div>
				</ion-col>
			</ion-row>
		</ng-container>

		<!-- -------------------------- Company Certificate of Incorporation ------------------------ -->
		<ng-container *ngIf="data.file_id == 8">

			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}} <span *ngIf="data.is_mandatory">*</span></p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="companyCertificate" class="file-info h-40 fs-16 fw-500"
							[title]="companyCertificate">
							<span>{{ companyCertificate}}</span>
						</div>
						<div *ngIf="!companyCertificate" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}</div>
						<button *ngIf="(!companyCertificate || companyCertificate === null)  && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #companyCertificate
								(click)="clearFileInput(companyCertificate, 'companyCertificate')"
								(change)="onFileSelect( data ,$event.target, 'companyCertificate')" />
						</button>
						<div *ngIf="companyCertificate  && !isDisable">
							<button *ngIf="companyCertificate && from != 'documentRegister'"
								(click)="viewFile('companyCertificate')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="companyCertificate && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('companyCertificate',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600">
								<span> Remove</span>
							</button>
							<button *ngIf="data.is_mandatory && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> Update</span>
								<input type="file" #companyCertificate
									(click)="clearFileInput(companyCertificate, 'companyCertificate')"
									(change)="onFileSelect( data ,$event.target, 'companyCertificate',true)" />
							</button>
						</div>

					</div>
				</ion-col>
			</ion-row>
		</ng-container>


		<!-- -------------------------- Company Pan Card ------------------------ -->
		<ng-container *ngIf="data.file_id == 10">

			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}} <span *ngIf="data.is_mandatory">*</span></p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="companyPanCard" class="file-info h-40 fs-16 fw-500" [title]="companyPanCard">
							<span>{{
								companyPanCard
								}}</span>
						</div>
						<div *ngIf="!companyPanCard" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}
						</div>
						<button *ngIf="(!companyPanCard || companyPanCard === null ) && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #companyPanCard
								(click)="clearFileInput(companyPanCard, 'companyPanCard')"
								(change)="onFileSelect( data ,$event.target, 'companyPanCard')" />
						</button>
						<div *ngIf="companyPanCard  && !isDisable">
							<button *ngIf="companyPanCard && from != 'documentRegister'"
								(click)="viewFile('companyPanCard')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="companyPanCard && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('companyPanCard',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600">
								<span> Remove</span>
							</button>
							<button *ngIf="data.is_mandatory && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> Update</span>
								<input type="file" #companyPanCard
									(click)="clearFileInput(companyPanCard, 'companyPanCard')"
									(change)="onFileSelect( data ,$event.target, 'companyPanCard',true)" />
							</button>
						</div>

					</div>
				</ion-col>
			</ion-row>

		</ng-container>



		<!-- --------------------------  Bank Statement for 6 months ------------------------ -->
		<ng-container *ngIf="data.file_id == 3">

			<p class="fs-16 fw-500 pt-10 pb-5"> {{data.document}} <span *ngIf="data.is_mandatory">*</span></p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="bankStatement" class="file-info h-40 fs-16 fw-500" [title]="bankStatement"><span>{{
								bankStatement
								}}</span></div>
						<div *ngIf="!bankStatement" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}
						</div>
						<button *ngIf="(!bankStatement || bankStatement === null ) && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #bankStatement (click)="clearFileInput(bankStatement, 'bankStatement')"
								(change)="onFileSelect( data ,$event.target, 'bankStatement')" />
						</button>
						<div *ngIf="bankStatement  && !isDisable">
							<button *ngIf="bankStatement && from != 'documentRegister'"
								(click)="viewFile('bankStatement')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="bankStatement && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('bankStatement',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600">
								<span> Remove</span>
							</button>


							<button *ngIf="data.is_mandatory && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> Update</span>
								<input type="file" #bankStatement
									(click)="clearFileInput(bankStatement, 'bankStatement')"
									(change)="onFileSelect( data ,$event.target, 'bankStatement',true)" />
							</button>
						</div>

					</div>
				</ion-col>
			</ion-row>
		</ng-container>



		<!-- --------------------------  ITR File------------------------ -->
		<ng-container *ngIf="data.file_id == 12">

			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}} <span *ngIf="data.is_mandatory">*</span></p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="itrFile" class="file-info h-40 fs-16 fw-500" [title]="itrFile"><span>{{
								itrFile
								}}</span></div>
						<div *ngIf="!itrFile" class="file-info like-placeholder fs-16 fw-500">Upload {{data.document}}
						</div>
						<button *ngIf="(!itrFile || itrFile === null)  && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #itrFile (click)="clearFileInput(itrFile, 'itrFile')"
								(change)="onFileSelect( data ,$event.target, 'itrFile')" />
						</button>
						<div *ngIf="itrFile  && !isDisable">
							<button *ngIf="itrFile && from != 'documentRegister'" (click)="viewFile('itrFile')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="itrFile && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('itrFile',data)" class="w-112 h-45 clear-btn pointer fs-14 fw-600">
								<span> Remove</span>
							</button>

							<button *ngIf="data.is_mandatory && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> Update</span>
								<input type="file" #itrFile (click)="clearFileInput(itrFile, 'itrFile')"
									(change)="onFileSelect( data ,$event.target, 'itrFile',true)" />
							</button>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ng-container>



		<!-- --------------------------  Shareholding Pattern ------------------------ -->
		<ng-container *ngIf="data.file_id == 7">
			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}} <span *ngIf="data.is_mandatory">*</span></p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="shareHoldingPattern" class="file-info h-40 fs-16 fw-500"
							[title]="shareHoldingPattern"><span>{{
								shareHoldingPattern
								}}</span></div>
						<div *ngIf="!shareHoldingPattern" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}
						</div>
						<button *ngIf="(!shareHoldingPattern || shareHoldingPattern === null) && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #shareHoldingPattern
								(click)="clearFileInput(shareHoldingPattern, 'shareHoldingPattern')"
								(change)="onFileSelect( data ,$event.target, 'shareHoldingPattern')" />
						</button>
						<div *ngIf="shareHoldingPattern  && !isDisable">
							<button *ngIf="shareHoldingPattern && from != 'documentRegister'"
								(click)="viewFile('shareHoldingPattern')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="shareHoldingPattern && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('shareHoldingPattern',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600">
								<span> Remove</span>
							</button>

							<button *ngIf="data.is_mandatory && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> Update</span>
								<input type="file" #shareHoldingPattern
									(click)="clearFileInput(shareHoldingPattern, 'shareHoldingPattern')"
									(change)="onFileSelect( data ,$event.target, 'shareHoldingPattern',true)" />
							</button>
						</div>

					</div>
				</ion-col>
			</ion-row>
		</ng-container>

		<!-- --------------------------  Electiri City Bill ------------------------ -->
		<ng-container *ngIf="data.file_id == 13">
			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}} <span *ngIf="data.is_mandatory">*</span></p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="electricitybill" class="file-info h-40 fs-16 fw-500" [title]="electricitybill">
							<span>{{electricitybill}}</span>
						</div>
						<div *ngIf="!electricitybill" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}
						</div>
						<button *ngIf="(!electricitybill || electricitybill === null)  && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #electricitybill
								(click)="clearFileInput(electricitybill, 'electricitybill')"
								(change)="onFileSelect( data ,$event.target, 'electricitybill')" />
						</button>
						<div *ngIf="electricitybill  && !isDisable">
							<button *ngIf="electricitybill && from != 'documentRegister'"
								(click)="viewFile('electricitybill')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="electricitybill && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('electricitybill',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600 ">
								<span> Remove</span>
							</button>

							<button *ngIf="data.is_mandatory && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> Update</span>
								<input type="file" #electricitybill
									(click)="clearFileInput(electricitybill, 'electricitybill')"
									(change)="onFileSelect( data ,$event.target, 'electricitybill',true)" />
							</button>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ng-container>



		<!-- --------------------------  Digital Signature ------------------------ -->
		<ng-container *ngIf="data.file_id == 14">
			<p class="fs-16 fw-500 pt-10 pb-5">{{data.document}} <span *ngIf="data.is_mandatory">*</span></p>
			<ion-row class="mb-5 id-proof">
				<ion-col>
					<div class="upload-btn">
						<div *ngIf="signature" class="file-info h-40 fs-16 fw-500" [title]="signature">
							<span>{{signature}}</span>
						</div>
						<div *ngIf="!signature" class="file-info like-placeholder fs-16 fw-500">Upload
							{{data.document}}
						</div>
						<button *ngIf="(!signature || signature === null)  && !isDisable"
							class="file-upload-button clear-btn pointer fs-14 fw-600">
							<span> Upload</span>
							<input type="file" #signature (click)="clearFileInput(signature, 'signature')"
								(change)="onFileSelect( data ,$event.target, 'signature')" />
						</button>
						<div *ngIf="signature  && !isDisable">
							<button *ngIf="signature && from != 'documentRegister'" (click)="viewFile('signature')"
								class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
								<span>View</span>
							</button>
							<button *ngIf="signature && !isSellerApproval && !data.is_mandatory"
								(click)="removeFile('signature',data)"
								class="w-112 h-45 clear-btn pointer fs-14 fw-600 ">
								<span> Remove</span>
							</button>

							<button *ngIf="data.is_mandatory && from != 'documentRegister'"
								class="file-upload-button clear-btn pointer fs-14 fw-600">
								<span> Update</span>
								<input type="file" #signature (click)="clearFileInput(signature, 'signature')"
									(change)="onFileSelect( data ,$event.target, 'signature',true)" />
							</button>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ng-container>
	</ng-container>
</div>

<ng-container *ngIf="from == 'documentRegister'"> <!-- //  -->
	<div class="form-button mt-30 "> <!-- // *ngIf="isCompany" -->
		<button [disabled]="isLoading" (click)="onDocumentSubmit()" type="primary" class="mr-20  w-182 submit-btn">
			<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
			{{progress == 100 ? 'Save' : 'Save & Next'}}
		</button>
		<button class="clear-btn w-147" (click)="documentCancel()">Cancel</button>
	</div>
</ng-container>