<div class="pl-50 pt-25 fw-600 fs-22 mb-m20" *ngIf="checkoutAddress">Select Address</div>
<div [ngClass]="!checkoutAddress ? 'addresses p-50' : 'm-50'">
	<div *ngIf="!checkoutAddress" class="address-grid">
		<ion-grid>
			<ion-row class="mb-30" *ngIf="addressList && addressList.length > 0">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class=" pd-0"
					*ngFor="let address of addressList;let i = index">
					<div class="fs-16 fw-600 mb-20 heading">{{address.heading}}

					</div>
					<div class="fs-16 fw-500 mb-10">{{address.title}}</div>
					<div class="address-holder p-20" [ngClass]="i === 0 ? 'mr-25' : ''">
						<div class="address-type fs-14 fw-600 mb-10">
							{{address.address_type['address_type'] }}
							<span *ngIf="address.other_address_type "> &nbsp;({{address.other_address_type}})</span>
						</div>
						<div class="full-address-container">
							<div class="address-name mb-5 fs-16 fw-600">
								{{address.company_name}}
							</div>
							<div class="full-address fs-16 fw-500">
								{{address.building_address}} <br>{{address.address}}
							</div>
						</div>
						<p> GST Number: {{address.gst_number || '--'}}</p>
						<div class=" pt-25 edit-pen-con" (click)="editAddress(address)">
							<div class=" pointer  tooltip" data-tooltip="Edit">
								<img src="./assets/icon/edit.svg" class="tooltip" data-tooltip="Edit" alt="Edit Icon" />
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
	<div class="address-list">
		<div class="mt-10 mb-10 display-flex justify-end" *ngIf="!checkoutAddress && !getPlatformService.isDesktop"
			[ngClass]="listOfAllData.length > 0 || addressList.length > 0 ? 'form-button' : 'flex-center' ">
			<button class="submit-btn w-200" (click)="addAddress()" [disabled]="isCustomerApproval">+ Add New
				Address</button>
		</div>
		<app-table-listing [tableHeight]="checkoutAddress ? 'modalBox' :''" [pageType]="'customer_address'"
			*ngIf="listOfAllData && listOfAllData.length > 0" [settings]="column_settings" [isBtn]="isBtn"
			[listOfAllData]="listOfAllData" [isClose]="checkoutAddress" [widthConfig]="widthConfig"
			[checkoutAddress]="checkoutAddress" [selectedAddress]="selected_address"></app-table-listing>

		<div class="mt-20" *ngIf="!checkoutAddress && getPlatformService.isDesktop"
			[ngClass]="listOfAllData.length > 0 || addressList.length > 0 ? 'form-button' : 'flex-center' ">
			<button class="submit-btn w-200" (click)="addAddress()" [disabled]="isCustomerApproval">+ Add New
				Address</button>
		</div>
	</div>
</div>