import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { profileTypeEnum } from 'src/app/_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';

const MAX_FILE_SIZE_IMAGE = 5 * 1024 * 1024;
const MIN_FILE_SIZE_IMAGE = 5 * 1024;

@Component({
	selector: 'app-document',
	templateUrl: './document.component.html',
	styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit, OnDestroy {
	public subscription = new Subscription();
	@Output() uploadEvent = new EventEmitter();
	@ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
	@Input() customEventFromParent: string;
	@Input() isCompany = false;
	progress = 0;
	fileInfo: string;
	aadharFile: any = []; // Initialize as an array
	gstCertificateFile: string = '';
	officeFile: any = []; // Initialize as an array
	directorPanCard: any = [];
	companyCertificate: string = '';
	companyPanCard: string = '';
	bankStatement: string = '';
	msmeCertificate: string = '';
	customerAadharFile: string = '';
	customerPanCardFile: string = '';
	itrFile: string = '';
	shareHoldingPattern: string = '';
	electricitybill: string = '';
	signature: string = '';

	isLoading = false;

	aadharFileData;
	gstCertificateFileData;
	officeFilesData;
	directorPAnCardData;
	companyCertificateData;
	companyPanCardData;
	bankStatementData;
	msmeCertificateData;
	customerAadharFileData;
	customerPanCardFileData;
	itrFileData;
	shareHoldingPatternData;
	electricitybillData;
	signatureData;

	@Input() isSellerApproval;
	@Input() from;
	idFileUrl: string;
	gstCertificateFileUrl: string;
	documentReset: boolean = false;
	isToastVisible: boolean = false;
	profileEnum = profileTypeEnum;
	userData: any;
	documentList: any;
	isDisable: boolean;
	constructor(private commonService: CommonService,
		private toasterService: ToasterService,
		private dataService: ApiService,
		public navCtrl: NavController,
		private cdr: ChangeDetectorRef) {
		this.subscription = this.commonService.clearData.subscribe(data => {
			if (data && data.isDocument) {
				this.documentReset = false;
			} else {
				this.aadharFile = null;
				this.gstCertificateFile = null;
				this.documentReset = true;

			}
		})
	}

	ngOnInit() {
		const data = this.commonService.localStorageGet('accessToken') ? this.commonService.localStorageGet('info') : null;
		this.userData = data ? JSON.parse(data) : {};

		this.getDocumentSubMasterList();

		if (this.isCompany) {
			this.getCompanyDetail();
			this.getProgressBar();
		}

		this.commonService.getUserDetail().then((data: any) => {
			if ((data.seller_approval_status == "PENDING" || data.approval_status == "PENDING") && this.from != 'documentRegister') {
				this.isDisable = true;
			}
		}, error => {
		});


	}

	getDocumentSubMasterList() {
		let userRole;
		if (this.from == 'documentRegister') {

			userRole = this.userData.approval_status == "DOC_PENDING" ? 'CUSTOMER' : "SELLER"
		}
		else {
			userRole = this.userData.isCustomer == true ? 'CUSTOMER' : "SELLER"
		}

		let params = {
			user_type: this.userData.customer_type == this.profileEnum.Enterprise ? 'ENTERPRISE' : "INDIVIDUAL",//'ENTERPRISE',
			user_role: userRole
		}
		this.subscription.add(
			this.dataService.get(URLS.getSubMasterLimit, params).subscribe(data => {
				if (data['code'] == 200) {
					this.documentList = data['data'];

					if (this.from == 'my-profile' || this.from == 'seller-profile') {
						this.getDocumentList();
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}
	getDocumentList() {
		this.isLoading = true;
		let userRole;
		if (this.from == 'documentRegister') {
			userRole = this.userData.approval_status == "DOC_PENDING" ? 'CUSTOMER' : "SELLER"
		}
		else {
			userRole = this.userData.isCustomer == true ? 'CUSTOMER' : "SELLER"
		}
		let params = {
			user_type: this.userData.customer_type == this.profileEnum.Enterprise ? 'ENTERPRISE' : "INDIVIDUAL",//'ENTERPRISE',
			user_role: userRole
		}
		this.subscription.add(
			this.dataService.get(URLS.documentList, params).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					// this.documentList = data['data'];
					if (data['data']) {
						// Extract and combine `user_document_mapping` arrays
						const userDocumentMappings = data['data'].flatMap(
							(item: any) => item.user_document_mapping
						);

						userDocumentMappings.forEach(response => {
							this.documentList.forEach(element => {
								if (element.file_id == 1 && response.file_id == 1 && response.document_files) {
									element.document_files = response.document_files;
									this.gstCertificateFile = this.getFileNameFromUrl(response.document_files);
									this.gstCertificateFileData = response.document_files;
									this.gstCertificateFileUrl = response.document_files[0];
								}
								if (element.file_id == 8 && response.file_id == 8 && response.document_files) {
									element.document_files = response.document_files;
									this.companyCertificate = this.getFileNameFromUrl(response.document_files);
									this.companyCertificateData = response.document_files;
								}
								if (element.file_id == 5 && response.file_id == 5 && response.document_files && response.document_files.length > 0) { // multiple access
									element.document_files = response.document_files;
									response.document_files.forEach(element => {
										this.aadharFile.push({ name: this.getFileNameFromUrl(element, true), file: element })
									});
								}
								if (element.file_id == 9 && response.file_id == 9 && response.document_files) {
									element.document_files = response.document_files;
									this.msmeCertificate = this.getFileNameFromUrl(response.document_files)
									this.msmeCertificateData = response.document_files;
								}
								if (element.file_id == 6 && response.file_id == 6 && response.document_files) {
									element.document_files = response.document_files;
									this.customerAadharFile = this.getFileNameFromUrl(response.document_files)
									this.customerAadharFileData = response.document_files;
								}
								if (element.file_id == 11 && response.file_id == 11 && response.document_files) {
									element.document_files = response.document_files;
									this.customerPanCardFile = this.getFileNameFromUrl(response.document_files)
									this.customerPanCardFileData = response.document_files;
								}
								if (element.file_id == 2 && response.file_id == 2 && response.document_files && response.document_files.length > 0) { //multiple access
									element.document_files = response.document_files;
									response.document_files.forEach(element => {
										this.officeFile.push({ name: this.getFileNameFromUrl(element, true), file: element })
									});
								}
								if (element.file_id == 10 && response.file_id == 10 && response.document_files) {
									element.document_files = response.document_files;
									this.companyPanCard = this.getFileNameFromUrl(response.document_files);
									this.companyPanCardData = response.document_files;
								}
								if (element.file_id == 4 && response.file_id == 4 && response.document_files && response.document_files.length > 0) { //multiple access
									element.document_files = response.document_files;
									response.document_files.forEach(element => {
										this.directorPanCard.push({ name: this.getFileNameFromUrl(element, true), file: element })
									});
								}
								if (element.file_id == 3 && response.file_id == 3 && response.document_files) {
									element.document_files = response.document_files;
									this.bankStatement = this.getFileNameFromUrl(response.document_files);
									this.bankStatementData = response.document_files;
								}
								if (element.file_id == 12 && response.file_id == 12 && response.document_files) {
									element.document_files = response.document_files;
									this.itrFile = this.getFileNameFromUrl(response.document_files);
									this.itrFileData = response.document_files;
								}
								if (element.file_id == 7 && response.file_id == 7 && response.document_files) {
									element.document_files = response.document_files;
									this.shareHoldingPattern = this.getFileNameFromUrl(response.document_files);
									this.shareHoldingPatternData = response.document_files;
								}
								if (element.file_id == 13 && response.file_id == 13 && response.document_files) {
									element.document_files = response.document_files;
									this.electricitybill = this.getFileNameFromUrl(response.document_files);
									this.electricitybillData = response.document_files;
								}
								if (element.file_id == 14 && response.file_id == 14 && response.document_files) {
									element.document_files = response.document_files;
									this.signature = this.getFileNameFromUrl(response.document_files);
									this.signatureData = response.document_files;
								}
							});
						});



					}
				}
				else {
					this.isLoading = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}
	getProgressBar() {
		this.subscription.add(
			this.dataService.get(URLS.sellerProgressBar).subscribe(data => {
				if (data['code'] == 200) {
					this.progress = data['data']['progress'];
					let localSellerApproval = this.commonService.localStorageGet('seller_approval') == 'PENDING' ? true : false;
					if (localSellerApproval && this.progress == 100) {
						//   this.isSellerApproval = true;
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	/**
  * Called when the value of the file input changes, i.e. when a file has been
  * selected for upload.
  *
  * @param input the file input HTMLElement
  */
	onIdProofSelect(input: HTMLInputElement): void {
		this.cdr.detectChanges();

		/**
		 * Format the size to a human readable string
		 *
		 * @param bytes
		 * @returns the formatted string e.g. `105 kB` or 25.6 MB
		 */
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;

			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}

			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}
		const file = input.files[0];
		let fileSize = file['size'];
		let fileExtension = file['name'].split('.').pop().toLocaleLowerCase();
		if (fileExtension == 'webm' || fileExtension == 'mkv' || fileExtension == 'flv' || fileExtension == 'mp4' ||
			fileExtension == 'mpg' || fileExtension == 'mpv' || fileExtension == 'ogg' || fileExtension == 'ogv'
			|| fileExtension == 'wmv') {
			this.isToastVisible = true;
			this.toasterService.warningToast("Please select JPG, JPEG ,PNG image Or file !", 'top');
			this.toastMethodDisable();
			return;
		}

		if (fileSize > MAX_FILE_SIZE_IMAGE || fileSize < MIN_FILE_SIZE_IMAGE) {
			this.isToastVisible = true;
			this.toasterService.warningToast("Please upload document within minimum 5KB or maximum 5MB");
			this.toastMethodDisable();
			input = null;
			this.aadharFileData = null;
			this.aadharFile = null;
			return;
		}

		this.aadharFileData = file;
		this.aadharFile = `${file.name}`;
		this.uploadEvent.emit({ ID: 'aadharFile', CERTIFICATE: this.aadharFileData });

	}

	onCertificateSelect(input: HTMLInputElement, data: any, is_update?: boolean): void {

		/**
		 * Format the size to a human readable string
		 *
		 * @param bytes
		 * @returns the formatted string e.g. `105 kB` or 25.6 MB
		 */
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;

			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}

			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}
		if (this.isToastVisible) {
			return;
		}
		const file = input.files[0];
		let fileSize = file['size'];
		let fileExtension = file['name'].split('.').pop().toLocaleLowerCase();
		if (fileExtension == 'webm' || fileExtension == 'mkv' || fileExtension == 'flv' || fileExtension == 'mp4' ||
			fileExtension == 'mpg' || fileExtension == 'mpv' || fileExtension == 'ogg' || fileExtension == 'ogv' ||
			fileExtension == 'wmv') {
			this.isToastVisible = true;
			this.toasterService.warningToast("Please select JPG, JPEG ,PNG image Or file !", 'top');
			this.toastMethodDisable();
			return;
		}
		if (fileSize > MAX_FILE_SIZE_IMAGE || fileSize < MIN_FILE_SIZE_IMAGE) {
			this.gstCertificateFile = null;
			this.gstCertificateFileData = null;
			this.isToastVisible = true
			this.toasterService.warningToast("Please upload document within minimum 5KB or maximum 5MB");
			this.toastMethodDisable();
			return;
		}
		this.gstCertificateFileData = file;
		this.gstCertificateFile = `${file.name}`;
		this.uploadEvent.emit({ ID: 'gstCertificateFile', fileData: this.gstCertificateFileData });
		this.cdr.detectChanges();

		if (is_update) {
			this.updateUploadDocument(data);
		}
		else {
			this.addSingleUploadDocument(data);
		}

	}

	onFileSelect(data: any, input: HTMLInputElement, fileType: string, is_update?: boolean): void {
		// Function to format bytes (not used in this example but kept for reference)
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;

			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}

			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}

		if (this.isToastVisible) {
			return;
		}

		const files = input.files;
		if (!files || files.length === 0) {
			return; // Handle no files selected
		}

		const validFiles: File[] = [];
		const imageExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'docx'];
		const MAX_FILE_SIZE_IMAGE = 5 * 1024 * 1024; // 5MB
		const MIN_FILE_SIZE_IMAGE = 5 * 1024; // 5KB

		// Process each file
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const fileSize = file.size;
			const fileExtension = file.name.split('.').pop().toLowerCase();


			// Check for valid image file types
			if (!imageExtensions.includes(fileExtension)) {
				this.isToastVisible = true;
				this.toasterService.warningToast("Please select JPG, JPEG, or PNG images!", 'top');
				this.toastMethodDisable();
				return;
			}



			// Check file size
			if (fileSize > MAX_FILE_SIZE_IMAGE || fileSize < MIN_FILE_SIZE_IMAGE) {
				this.isToastVisible = true;
				this.toasterService.warningToast("Please upload files within minimum 5KB or maximum 5MB");
				this.toastMethodDisable();
				return;
			}

			validFiles.push(file);
		}

		// Validate number of files for officeFiles
		if (fileType === 'aadharFiles') {
			if (this.aadharFile.length >= 10) {
				this.isToastVisible = true;
				this.toasterService.warningToast("Please upload between 1 and 10 files for Aadhar card.");
				this.toastMethodDisable();
				return;
			}
			// Store all valid file names

			let selectedFiles = [];
			for (let i = 0; i < files.length; i++) {
				selectedFiles.push(files[i]);
			}

			// const selectedFiles = Array.from(input.files); // Convert FileList to array
			this.aadharFile = [...this.aadharFile, ...selectedFiles];

			// this.aadharFileData = Array.from(validFiles).map(file => file.name).join(', '); // Join file names with a comma
			this.uploadEvent.emit({ ID: 'aadharFile', fileData: validFiles });

			if (is_update) {
				this.updateUploadDocument(data);
			}
			else {
				this.addSingleUploadDocument(data);
			}

		}

		if (fileType === 'officeFiles') {
			if (this.officeFile.length >= 10) {
				this.isToastVisible = true;
				this.toasterService.warningToast("Please upload between 1 and 10 files for office files.");
				this.toastMethodDisable();
				return;
			}
			// Store all valid file names

			let selectedFiles = [];
			for (let i = 0; i < files.length; i++) {
				selectedFiles.push(files[i]);
			}

			// const selectedFiles = Array.from(input.files); // Convert FileList to array
			this.officeFile = [...this.officeFile, ...selectedFiles];

			// this.officeFilesData = Array.from(validFiles).map(file => file.name).join(', '); // Join file names with a comma
			this.uploadEvent.emit({ ID: 'officeFile', fileData: validFiles });
			if (is_update) {
				this.updateUploadDocument(data);
			}
			else {
				this.addSingleUploadDocument(data);
			}
		} if (fileType === 'directorPanCard') {
			if (this.directorPanCard.length >= 10) {
				this.isToastVisible = true;
				this.toasterService.warningToast("Please upload between 1 and 10 PAN Cards.");
				this.toastMethodDisable();
				return;
			}
			// Store all valid file names

			let selectedFiles = [];
			for (let i = 0; i < files.length; i++) {
				selectedFiles.push(files[i]);
			}

			// const selectedFiles = Array.from(input.files); // Convert FileList to array
			this.directorPanCard = [...this.directorPanCard, ...selectedFiles];

			// this.directorPAnCardData = Array.from(validFiles).map(file => file.name).join(', '); // Join file names with a comma
			this.uploadEvent.emit({ ID: 'directorPanCard', fileData: validFiles });
			if (is_update) {
				this.updateUploadDocument(data);
			}
			else {
				this.addSingleUploadDocument(data);
			}
		} else {
			// Handle other file types, only allow one file

			// Check for valid image file types for signature
			if (!validFiles[0].name.includes('png') && fileType == 'signature') {
				this.isToastVisible = true;
				this.toasterService.warningToast("Please select PNG images for signature!", 'top');
				this.toastMethodDisable();
				return;
			}

			if (validFiles.length > 0) {
				this[fileType] = validFiles[0].name; // Assign single file name
				this[fileType + 'Data'] = validFiles[0];
				this.uploadEvent.emit({ ID: fileType, fileData: validFiles[0] });
				if (is_update) {
					this.updateUploadDocument(data);
				}
				else {
					this.addSingleUploadDocument(data);
				}
			}
		}

		this.cdr.detectChanges();
	}


	clearFileInput(fileInput: HTMLInputElement, fileType: string): void {
		fileInput.value = ''; // Clear the file input value

		// Clear the corresponding file field based on fileType
		switch (fileType) {
			case 'officeFiles':
				break;
			case 'companyCertificate':
				this.companyCertificate = '';
				break;
			case 'companyPanCard':
				this.companyPanCard = '';
				break;
			case 'bankStatement':
				this.bankStatement = '';
				break;
			case 'msmeCertificate':
				this.msmeCertificate = '';
				break;
			case 'customerAadharFile':
				this.customerAadharFile = '';
				break;
			case 'customerPanCardFile':
				this.customerPanCardFile = '';
				break;
			case 'itrFile':
				this.itrFile = '';
				break;
			case 'shareHoldingPattern':
				this.shareHoldingPattern = '';
				break;
			case 'electricitybill':
				this.electricitybill = '';
				break;
			case 'signature':
				this.signature = '';
				break;
			default:
				console.warn("Invalid file type for clearing input");
				break;
		}
	}

	clearCertificateFileInput(fileInput: HTMLInputElement): void {
		fileInput.value = ''; // Clear the file input value
	}

	clearProofFileInput(fileInput: HTMLInputElement): void {
		fileInput.value = ''; // Clear the file input value
	}
	getCompanyDetail() {
		this.subscription.add(
			this.dataService.get(URLS.sellerCompanyDetail).subscribe(data => {
				if (data['code'] == 200) {
					let companyInfo = data['data'];
					this.gstCertificateFile = companyInfo['cr_certificate_filename']
					this.aadharFile = companyInfo['id_proof_filename']
					this.idFileUrl = companyInfo['id_proof_url'];
					this.gstCertificateFileUrl = companyInfo['cr_certificate_url'];
					this.gstCertificateFileData = companyInfo['cr_certificate_filename']
					this.aadharFileData = companyInfo['id_proof_filename']
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	removeProof() {
		this.aadharFileData = null;
		this.aadharFile = null;
		this.uploadEvent.emit({ ID: 'aadharFile', fileData: null });

	}

	removeCert(data) {
		this.gstCertificateFile = null;
		this.gstCertificateFileData = null;
		this.uploadEvent.emit({ ID: 'gstCertificateFile', fileData: null });
		if (data.is_mandatory != true) {
			this.deleteFromDocument(data);
		}
	}

	removeFile(fileType: string, data: any, index?: number,): void {
		// Reset the corresponding file field based on fileType
		switch (fileType) {
			case 'officeFiles':
				if (this.officeFile.length <= 1) {
					this.toasterService.errorToast("Not able to remove files because it's mandatory");
					return;
				}
				this.officeFile.splice(index, 1);
				this.officeFilesData = '';
				this.uploadEvent.emit({ ID: 'officeFiles', fileData: null });
				this.deleteFromDocument(data, true);
				break;
			case 'directorPanCard':
				if (this.directorPanCard.length <= 1) {
					this.toasterService.errorToast("Not able to remove files because it's mandatory");
					return;
				}
				this.directorPanCard.splice(index, 1);
				this.directorPAnCardData = '';
				this.uploadEvent.emit({ ID: 'directorPanCard', fileData: null });
				this.deleteFromDocument(data, true);
				break;
			case 'aadharFiles':
				if (this.aadharFile.length <= 1) {
					this.toasterService.errorToast("Not able to remove files because it's mandatory");
					return;
				}
				this.aadharFile.splice(index, 1);
				this.aadharFileData = '';
				this.uploadEvent.emit({ ID: 'aadharFiles', fileData: null });
				this.deleteFromDocument(data, true);
				break;
			case 'companyCertificate':
				this.companyCertificate = '';
				this.companyCertificateData = '';
				this.uploadEvent.emit({ ID: 'companyCertificate', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'companyPanCard':
				this.companyPanCard = '';
				this.companyPanCardData = '';
				this.uploadEvent.emit({ ID: 'companyPanCard', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'bankStatement':
				this.bankStatement = '';
				this.bankStatementData = '';
				this.uploadEvent.emit({ ID: 'bankStatement', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'msmeCertificate':
				this.msmeCertificate = '';
				this.msmeCertificateData = '';
				this.uploadEvent.emit({ ID: 'msmeCertificate', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'customerAadharFile':
				this.customerAadharFile = '';
				this.customerAadharFileData = '';
				this.uploadEvent.emit({ ID: 'customerAadharFile', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'customerPanCardFile':
				this.customerPanCardFile = '';
				this.customerPanCardFileData = '';
				this.uploadEvent.emit({ ID: 'customerPanCardFile', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'itrFile':
				this.itrFile = '';
				this.itrFileData = '';
				this.uploadEvent.emit({ ID: 'itrFile', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'shareHoldingPattern':
				this.shareHoldingPattern = '';
				this.shareHoldingPatternData = '';
				this.uploadEvent.emit({ ID: 'shareHoldingPattern', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'electricitybill':
				this.electricitybill = '';
				this.electricitybillData = '';
				this.uploadEvent.emit({ ID: 'electricitybill', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			case 'signature':
				this.signature = '';
				this.signatureData = '';
				this.uploadEvent.emit({ ID: 'signature', fileData: null });
				if (data.is_mandatory != true) {
					this.deleteFromDocument(data);
				}
				break;
			default:
				console.warn("Invalid file type for removal");
				return;
		}
	}

	// onDocumentSubmit() {
	// 	if (this.isToastVisible) {
	// 		return;
	// 	}
	// 	let isFileNameChange: boolean = false
	// 	if (!this.gstCertificateFileData || !this.aadharFileData) {
	// 		this.isToastVisible = true;
	// 		this.toasterService.errorToast("Please add documents");
	// 		this.toastMethodDisable();
	// 		return;
	// 	}

	// 	if (this.aadharFile != this.aadharFileData) {
	// 		isFileNameChange = true;
	// 	}
	// 	if (this.gstCertificateFile != this.gstCertificateFileData) {
	// 		isFileNameChange = true;
	// 	}
	// 	if (!isFileNameChange) {
	// 		this.isToastVisible = true;
	// 		this.toasterService.warningToast("No data updated")
	// 		this.toastMethodDisable();
	// 		return
	// 	}
	// 	let formData = new FormData();

	// 	if (this.gstCertificateFileData) formData.append("cr_certificate", this.gstCertificateFileData);
	// 	if (this.aadharFileData) formData.append("id_proof", this.aadharFileData);
	// 	this.isLoading = true;
	// 	this.subscription.add(
	// 		this.dataService.put(URLS.sellerCompanyInfoUpdate, formData).subscribe(data => {
	// 			if (data['code'] == 200) {
	// 				this.isLoading = false;
	// 				this.toasterService.successToast(data['message']);
	// 				this.commonService.setEvent('documentUpdate', "true")
	// 			}
	// 			else {
	// 				this.isLoading = false;
	// 				this.toasterService.errorToast(data['message']);
	// 			}
	// 		}, error => {
	// 			this.isLoading = false;
	// 			this.toasterService.errorToast(error['message']);
	// 		})
	// 	)

	// }

	onDocumentSubmit() {
		if (this.isToastVisible) {
			return;
		}

		this.documentList.forEach(element => {
			if (element.file_id == 1) {
				element.documnentFile = this.gstCertificateFile;
				element.documnentFileData = this.gstCertificateFileData;
			}
			if (element.file_id == 8) {
				element.documnentFile = this.companyCertificate;
				element.documnentFileData = this.companyCertificateData;
			}
			if (element.file_id == 5) { // multiple access
				element.documnentFile = this.aadharFile;
				element.documnentFileData = this.aadharFile;
			}
			if (element.file_id == 9) {
				element.documnentFile = this.msmeCertificate;
				element.documnentFileData = this.msmeCertificateData;
			}
			if (element.file_id == 6) {
				element.documnentFile = this.customerAadharFile;
				element.documnentFileData = this.customerAadharFileData;
			}
			if (element.file_id == 11) {
				element.documnentFile = this.customerPanCardFile;
				element.documnentFileData = this.customerPanCardFileData;
			}
			if (element.file_id == 2) { //multiple access
				element.documnentFile = this.officeFile;
				element.documnentFileData = this.officeFile;
			}
			if (element.file_id == 10) {
				element.documnentFile = this.companyPanCard;
				element.documnentFileData = this.companyPanCardData;
			}
			if (element.file_id == 4) { //multiple access
				element.documnentFile = this.directorPanCard;
				element.documnentFileData = this.directorPanCard;
			}
			if (element.file_id == 3) {
				element.documnentFile = this.bankStatement;
				element.documnentFileData = this.bankStatementData;
			}
			if (element.file_id == 12) {
				element.documnentFile = this.itrFile;
				element.documnentFileData = this.itrFileData;
			}
			if (element.file_id == 7) {
				element.documnentFile = this.shareHoldingPattern;
				element.documnentFileData = this.shareHoldingPatternData;
			}
			if (element.file_id == 13) {
				element.documnentFile = this.electricitybill;
				element.documnentFileData = this.electricitybillData;
			}
			if (element.file_id == 14) {
				element.documnentFile = this.signature;
				element.documnentFileData = this.signatureData;
			}
		});

		let anyMissing;
		this.documentList.forEach(element => {
			if (element.is_mandatory && (!element.documnentFile || element.documnentFile == null || element.documnentFile.length < 1)) {
				this.isToastVisible = true;
				anyMissing = true;
				this.toasterService.warningToast("Please upload " + element.document + ".")
				this.toastMethodDisable();
				return;
			}
		})

		if (anyMissing == true) {
			return;
		}

		if (this.from == 'documentRegister') {

			let userRole;
			if (this.from == 'documentRegister') {
				userRole = this.userData.approval_status == "DOC_PENDING" ? 'CUSTOMER' : "SELLER"

			}
			else {
				userRole = this.userData.isCustomer == true ? 'CUSTOMER' : "SELLER"
			}

			let formData = new FormData();

			formData.append("user_type", this.userData.customer_type == this.profileEnum.Enterprise ? 'ENTERPRISE' : "INDIVIDUAL");
			formData.append("user_role", userRole);


			this.documentList.forEach(element => {
				if (element.documnentFileData && Array.isArray(element.documnentFileData)) {
					element.documnentFileData.forEach(arrayElement => {
						formData.append(element.document, arrayElement);
					});
				}
				else if (element.documnentFile) {
					formData.append(element.document, element.documnentFileData);
				}
			});

			this.isLoading = true;

			this.subscription.add(
				this.dataService.post(URLS.documentAdd, formData).subscribe(data => {
					if (data['code'] == 200) {
						this.isLoading = false;
						this.toasterService.successToast(data['message']);
						this.commonService.setEvent('documentUpdate', "true");
						if (this.from == 'documentRegister') {
							this.navCtrl.navigateRoot(['/login']);
						}
						else {
							if (userRole == 'CUSTOMER') {
								this.navCtrl.navigateRoot(['/home']);
							}
							else {
								this.navCtrl.navigateRoot(['/account-details/my-profile']);
							}
						}
					}
					else {
						this.isLoading = false;
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)

		}

	}

	buttonClick(value: string) {
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	documentCancel() {
		if (this.from == 'documentRegister') {
			this.navCtrl.navigateRoot('/login');
			return;
		}
		if (this.isToastVisible) {
			return;
		}
		this.getCompanyDetail();

		let isFileNameChange: boolean = false;
		if (this.aadharFile != this.aadharFileData) {
			isFileNameChange = true;
		}
		if (this.gstCertificateFile != this.gstCertificateFileData) {
			isFileNameChange = true;
		}
		if (!isFileNameChange) {
			this.isToastVisible = true;
			this.toasterService.warningToast("No data updated");
			this.toastMethodDisable();
			return
		}
	}

	toastMethodDisable() {
		setTimeout(() => {
			this.isToastVisible = false;
		}, 3010)
	}

	viewCert() {
		window.open(this.gstCertificateFileUrl, "_blank");
	}

	viewProof() {
		window.open(this.idFileUrl, "_blank");
	}

	getFileNameFromUrl(data: any, is_multiple?: boolean): string {
		// Use the `split` method to get the last part of the URL
		return is_multiple == true ? data.doc_url.split('/').pop() || '' : data[0].doc_url.split('/').pop() || '';
	}

	viewFile(fileType: string, index?: number, multiple?: boolean, url?: any): void {
		// Helper function to open URLs
		if (multiple && multiple == true) {
			window.open(url, "_blank");
		} else {
			// Reset the corresponding file field based on fileType
			switch (fileType) {
				case 'officeFiles':
					window.open(this.officeFilesData[0], "_blank");
					break;
				case 'directorPanCard':
					window.open(this.directorPAnCardData[0], "_blank");
					break;
				case 'aadharFiles':
					window.open(this.aadharFileData[0], "_blank");
					break;
				case 'companyCertificate':
					window.open(this.companyCertificateData[0], "_blank");
					break;
				case 'companyPanCard':
					window.open(this.companyPanCardData[0], "_blank");
					break;
				case 'bankStatement':
					window.open(this.bankStatementData[0], "_blank");
					break;
				case 'msmeCertificate':
					window.open(this.msmeCertificateData[0], "_blank");
					break;
				case 'customerAadharFile':
					window.open(this.customerAadharFileData[0], "_blank");
					break;
				case 'customerPanCardFile':
					window.open(this.customerPanCardFileData[0], "_blank");
					break;
				case 'itrFile':
					window.open(this.itrFileData[0], "_blank");
					break;
				case 'shareHoldingPattern':
					window.open(this.shareHoldingPatternData[0], "_blank");
					break;
				case 'electricitybill':
					window.open(this.electricitybillData[0], "_blank");
					break;
				case 'signature':
					window.open(this.signature[0], "_blank");
					break;
				default:
					console.warn("Invalid file type for removal");
					return;
			}
		}
	}

	updateUploadDocument(data) {

		console.info("updateUploadDocument ", data)
		// update api call
		let formData = new FormData();

		this.documentList.forEach(element => {
			if (element.file_id == 1) {
				element.documnentFile = this.gstCertificateFile;
				element.documnentFileData = this.gstCertificateFileData;
			}
			if (element.file_id == 8) {
				element.documnentFile = this.companyCertificate;
				element.documnentFileData = this.companyCertificateData;
			}
			if (element.file_id == 5) { // multiple access
				element.documnentFile = this.aadharFile;
				element.documnentFileData = this.aadharFile;
			}
			if (element.file_id == 9) {
				element.documnentFile = this.msmeCertificate;
				element.documnentFileData = this.msmeCertificateData;
			}
			if (element.file_id == 6) {
				element.documnentFile = this.customerAadharFile;
				element.documnentFileData = this.customerAadharFileData;
			}
			if (element.file_id == 11) {
				element.documnentFile = this.customerPanCardFile;
				element.documnentFileData = this.customerPanCardFileData;
			}
			if (element.file_id == 2) { //multiple access
				element.documnentFile = this.officeFile;
				element.documnentFileData = this.officeFile;
			}
			if (element.file_id == 10) {
				element.documnentFile = this.companyPanCard;
				element.documnentFileData = this.companyPanCardData;
			}
			if (element.file_id == 4) { //multiple access
				element.documnentFile = this.directorPanCard;
				element.documnentFileData = this.directorPanCard;
			}
			if (element.file_id == 3) {
				element.documnentFile = this.bankStatement;
				element.documnentFileData = this.bankStatementData;
			}
			if (element.file_id == 12) {
				element.documnentFile = this.itrFile;
				element.documnentFileData = this.itrFileData;
			}
			if (element.file_id == 7) {
				element.documnentFile = this.shareHoldingPattern;
				element.documnentFileData = this.shareHoldingPatternData;
			}
			if (element.file_id == 13) {
				element.documnentFile = this.electricitybill;
				element.documnentFileData = this.electricitybillData;
			}
			if (element.file_id == 14) {
				element.documnentFile = this.signature;
				element.documnentFileData = this.signatureData;
			}
		});


		const successVouchers = this.documentList.filter(document => document.document_id === data.document_id);


		if (successVouchers && successVouchers.length > 0) {
			formData.append("user_document_mapping_id", successVouchers[0].document_files[0].user_document_mapping_id);
			formData.append(successVouchers[0].document, successVouchers[0].documnentFileData);

			this.isLoading = true;

			this.subscription.add(
				this.dataService.put(URLS.documentUpdate, formData).subscribe(data => {
					if (data['code'] == 200) {
						this.isLoading = false;
						this.toasterService.successToast(data['message']);
						this.commonService.setEvent('documentUpdate', "true");
						this.clearAllFiles();
						this.getDocumentSubMasterList();
					}
					else {
						this.isLoading = false;
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)
		}

	}

	addSingleUploadDocument(singleDocument) {
		if (this.from == 'documentRegister') {
			return;
		}

		this.documentList.forEach(element => {
			if (element.file_id == 1) {
				element.documnentFile = this.gstCertificateFile;
				element.documnentFileData = this.gstCertificateFileData;
			}
			if (element.file_id == 8) {
				element.documnentFile = this.companyCertificate;
				element.documnentFileData = this.companyCertificateData;
			}
			if (element.file_id == 5) { // multiple access
				element.documnentFile = this.aadharFile;
				element.documnentFileData = this.aadharFile;
			}
			if (element.file_id == 9) {
				element.documnentFile = this.msmeCertificate;
				element.documnentFileData = this.msmeCertificateData;
			}
			if (element.file_id == 6) {
				element.documnentFile = this.customerAadharFile;
				element.documnentFileData = this.customerAadharFileData;
			}
			if (element.file_id == 11) {
				element.documnentFile = this.customerPanCardFile;
				element.documnentFileData = this.customerPanCardFileData;
			}
			if (element.file_id == 2) { //multiple access
				element.documnentFile = this.officeFile;
				element.documnentFileData = this.officeFile;
			}
			if (element.file_id == 10) {
				element.documnentFile = this.companyPanCard;
				element.documnentFileData = this.companyPanCardData;
			}
			if (element.file_id == 4) { //multiple access
				element.documnentFile = this.directorPanCard;
				element.documnentFileData = this.directorPanCard;
			}
			if (element.file_id == 3) {
				element.documnentFile = this.bankStatement;
				element.documnentFileData = this.bankStatementData;
			}
			if (element.file_id == 12) {
				element.documnentFile = this.itrFile;
				element.documnentFileData = this.itrFileData;
			}
			if (element.file_id == 7) {
				element.documnentFile = this.shareHoldingPattern;
				element.documnentFileData = this.shareHoldingPatternData;
			}
			if (element.file_id == 13) {
				element.documnentFile = this.electricitybill;
				element.documnentFileData = this.electricitybillData;
			}
			if (element.file_id == 14) {
				element.documnentFile = this.signature;
				element.documnentFileData = this.signatureData;
			}
		});


		let anyMissing;
		this.documentList.forEach(element => {
			if (element.is_mandatory && (!element.documnentFile || element.documnentFile == null || element.documnentFile.length < 1)) {
				this.isToastVisible = true;
				anyMissing = true;
				this.toasterService.warningToast("Please upload " + element.document + ".")
				this.toastMethodDisable();
				return;
			}
		})

		if (anyMissing == true) {
			return;
		}

		let formData = new FormData();

		let userRole;
		if (this.from == 'documentRegister') {
			userRole = this.userData.approval_status == "DOC_PENDING" ? 'CUSTOMER' : "SELLER"
		}
		else {
			userRole = this.userData.isCustomer == true ? 'CUSTOMER' : "SELLER"
		}

		formData.append("user_type", this.userData.customer_type == this.profileEnum.Enterprise ? 'ENTERPRISE' : "INDIVIDUAL");
		formData.append("user_role", userRole);

		const successVouchers = this.documentList.filter(document => document.document_id === singleDocument.document_id);


		if (successVouchers[0].documnentFileData && Array.isArray(successVouchers[0].documnentFileData)) {
			successVouchers[0].documnentFileData.forEach(arrayElement => {
				if (arrayElement && !arrayElement.hasOwnProperty('file')) {
					formData.append(successVouchers[0].document, arrayElement);
				}
			});
		}
		else if (successVouchers[0].documnentFile) {
			formData.append(successVouchers[0].document, successVouchers[0].documnentFileData);
		}

		this.isLoading = true;

		this.subscription.add(
			this.dataService.post(URLS.documentAdd, formData).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					this.toasterService.successToast(data['message']);
					this.commonService.setEvent('documentUpdate', "true");
					this.clearAllFiles();
					this.getDocumentSubMasterList();
				}
				else {
					this.isLoading = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)


	}


	deleteFromDocument(data, is_multiple?: boolean): void {
		let params = {};

		if (is_multiple) {
			params['user_document_mapping_id'] = data.file.user_document_mapping_id;
			params['is_deleted'] = true
		}
		else {
			params['user_document_mapping_id'] = data['document_files'][0].user_document_mapping_id;
			params['is_deleted'] = true;
		}

		this.isLoading = true;

		this.subscription.add(
			this.dataService.delete(URLS.documentDelete, params).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					this.toasterService.successToast(data['message']);
					this.commonService.setEvent('documentUpdate', "true");
					this.clearAllFiles();
					this.getDocumentSubMasterList();
				}
				else {
					this.isLoading = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)

	}

	clearAllFiles() {
		this.aadharFile = []; // Initialize as an array
		this.aadharFileData = null;

		this.gstCertificateFile = '';
		this.gstCertificateFileData = null;

		this.officeFile = []; // Initialize as an array
		this.officeFilesData = null;

		this.directorPanCard = [];
		this.directorPAnCardData = null;

		this.companyCertificate = '';
		this.companyCertificateData = null;

		this.companyPanCard = '';
		this.companyPanCardData = null;

		this.bankStatement = '';
		this.bankStatementData = null;

		this.msmeCertificate = '';
		this.msmeCertificateData = null;

		this.customerAadharFile = '';
		this.customerAadharFileData = null;

		this.customerPanCardFile = '';
		this.customerPanCardFileData = null;

		this.itrFile = '';
		this.itrFileData = null;

		this.shareHoldingPattern = '';
		this.shareHoldingPatternData = null;

		this.electricitybill = '';
		this.electricitybillData = null;

		this.signature = '';
		this.signatureData = null;

	}
}
