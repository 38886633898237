<div class="quote-detail" *ngIf="!isLoading">
	<div class="quote-top">
		<ion-row>
			<ion-col size-xs="6" size-sm="6" size-md="4" size-lg="3" size-xl="2">
				<ion-label class="fs-16 fw-600">Requested On</ion-label>
				<div class="fs-16 fw-500 mt-10">{{quoteDetail['created_at'] ? (quoteDetail['created_at'] | date
					:'dd-MM-yyyy h:mm a') : ''}}</div>
			</ion-col>
			<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="3" size-xl="2"
				*ngIf="quoteDetail.status=='REQUESTED'">
				<ion-label class="fs-16 fw-600">Expire On</ion-label>
				<div class="fs-16 fw-500 mt-10">{{quoteDetail['end_date'] ? (quoteDetail['end_date'] | date
					:'dd-MM-yyyy h:mm a') : ''}}</div>
			</ion-col>
			<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="3" size-xl="3">
				<ion-label class="fs-16 fw-600">Timeframe</ion-label>
				<div class="fs-16 fw-500 mt-10">{{quoteDetail['timeframe']}} hrs</div>
			</ion-col>
			<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="3" size-xl="2">
				<ion-label class="fs-16 fw-600">Radius</ion-label>
				<div class="fs-16 fw-500 mt-10">{{quoteDetail['radius']}} KM</div>
			</ion-col>
			<ng-container *ngIf="!isManageUserQuote">
				<ion-col *ngIf="isManageQuote" size-xs="6" size-sm="6" size-md="6" size-lg="3" size-xl="3">
					<ng-container *ngIf="status == 'RECEIVED' || status == 'SUBMITTED'">
						<button class="quote-btn" (click)="updateQuote()"
							[ngClass]="status == 'SUBMITTED' ? 'w-200 clear-btn' : 'w-147 submit-btn'">
							{{status == 'RECEIVED' ? 'Enter Quote' : status ==
							'ORDER_INITIATED'
							? 'View Order' : status ==
							'SUBMITTED' ? 'Withdraw Quotes' : ''}}
						</button>
					</ng-container>
					<ng-container *ngIf="status == 'EXPIRED' || status == 'CLOSED'">
						<span class="red-laber-noBorder fw-b" *ngIf="status == 'EXPIRED'"> You Missed To
							Send
							Quote!</span>
						<span *ngIf="status == 'CLOSED'">
							<div class="grey-laber-noBorder fw-500" *ngIf="quoteDetail['is_customer_closed']"> Buyer
								Closed
								This
								Request!</div>
							<div class="yellow-laber-noBorder fw-500" *ngIf="!quoteDetail['is_customer_closed']">
								Better
								Luck
								Next
								Time!</div>
						</span>
					</ng-container>
				</ion-col>
				<ion-col *ngIf="!isManageQuote" size-xs="6" size-sm="6" size-md="6" size-lg="3" size-xl="3">
					<ng-container>
						<button class="submit-btn w-115 mr-20" *ngIf="status == 'RECEIVED' || status == 'EXPIRED'
                    || status == 'CLOSED'" [disabled]="quoteDetail['is_rebid']" (click)="onReBid()">
							Re-Bid
						</button>
					</ng-container>
					<ng-container>
						<button *ngIf="status == 'REQUESTED' || status == 'RECEIVED'" class="clear-btn ph-10"
							(click)="onClose()">
							Withdraw Quotes
						</button>
					</ng-container>
				</ion-col>
			</ng-container>
		</ion-row>
	</div>
	<div class=" quote-middle mt-20">
		<ion-row class="mb-10">
			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="9" size-xl="9" class="pr-20">
				<div class="item-border " [ngClass]="!getPlatformService.isDesktop ? 'isDesktop' :''">
					<ion-row class="row-header-1 fw-600 fs-16 fw-600 fs-16">
						<ion-col size-lg="5" size-xl="5">
							<p class="row-header-1 fw-600 fs-16">Item</p>
						</ion-col>
						<ion-col size-lg="1" size-xl="1">
							<p class="row-header-1 fw-600 fs-16">Qty</p>
						</ion-col>
						<ion-col size-lg="2" size-xl="2">
							<p class="row-header-1 fw-600 fs-16">Price</p>
						</ion-col>
						<ion-col size-lg="2" size-xl="2" size-sm="3" size-md="3">
							<p class="row-header-1 fw-600 fs-16">Status</p>
						</ion-col>
						<ion-col size-lg="2" size-xl="2" size-sm="3" size-md="3" class="flex justify-end">
							<p class="row-header-1 fw-600 fs-16">Subtotal</p>
						</ion-col>
					</ion-row>
					<div class="divider-line mv-10"></div>
				</div>
				<ng-container>

					<div>
						<ion-row class="mb-20 mt-20">
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="5" size-xl="5" class="pl-0">
								<div class="product-detail">
									<div class="product-image">
										<img class="cursor-pointer" alt="Image" (click)="goToProductDetail()"
											[src]="quoteDetail['product_image'] ? quoteDetail['product_image'] :'/assets/product/product7.svg'">
									</div>
									<div class="product-description">
										<div class="product-name cursor-pointer" (click)="goToProductDetail()"
											[title]="quoteDetail.product_name">
											{{quoteDetail['product_name']}}
										</div>
										<div class="product-sku">
											<div class="sku-header">
												SKU:
											</div>
											<div class="sku" [title]="quoteDetail.sku">
												{{quoteDetail['sku']}}
											</div>
										</div>
										<div class="product-attribute mb-5"
											*ngFor="let oneAttr of quoteDetail['product_attributes']">
											<ng-container *ngIf="oneAttr.attribute_name ||  oneAttr.attribute_value">
												<div class="attribute-header" [title]="oneAttr.attribute_name">
													{{oneAttr.attribute_name}}
												</div>
												<div class="attribute attribute-value">
													: {{oneAttr.value}}
												</div>
											</ng-container>
										</div>

										<div class="product-sku">
											<div class="sku-header">
												Package:
											</div>
											<div class="sku">
												{{ quoteDetail &&
												quoteDetail.package_value ?
												quoteDetail.package_value :+ " "
												+quoteDetail.unit_name ? quoteDetail.unit_name:''
												}}
											</div>
										</div>

										<div class="expected-delivery mt-20"
											*ngIf="quoteDetail['estimated_delivery_date']">
											<span class="fs-14 fw-400 label"> Expected Delivery Date</span>
											<div class="fs-14 fw-600 mt-5">
												{{quoteDetail['estimated_delivery_date'] | date :'dd-MM-yyyy'}}
											</div>
										</div>
									</div>
								</div>

							</ion-col>
							<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="1" size-xl="1">

								<ion-col size-lg="2" size-xl="2"
									[ngClass]="getPlatformService.isDesktop ? 'hidden' :''">
									<p class="row-header-1 fw-600 fs-16">Qty</p>
								</ion-col>

								<p class="qty"><span class="fs-16  fw-500">{{quoteDetail['quantity']}}</span>
								</p>
							</ion-col>
							<ion-col size-xs="3.5" size-sm="3.5" size-md="3.5" size-lg="2" size-xl="2">
								<ion-col size-lg="2" size-xl="2"
									[ngClass]="getPlatformService.isDesktop ? 'hidden' :''">
									<p class="row-header-1 fw-600 fs-16">Price</p>
								</ion-col>
								<p *ngIf="quoteDetail['price_per_unit']"> <span class="fw-b">
										{{commonService.currencySymbol}}{{quoteDetail['price_per_unit'] ?
										( quoteDetail['price_per_unit']|number ): '0'
										}}/</span> <span class="fw-500">{{ quoteDetail &&
										quoteDetail.package_value ?
										quoteDetail.package_value: + " " +quoteDetail.unit_name
										}}</span>
								</p>
								<p *ngIf="!quoteDetail['price_per_unit']">--</p>
							</ion-col>
							<ion-col class="box-col" size-xs="3" size-sm="3" size-md="3" size-lg="2" size-xl="2">
								<ion-col size-lg="2" size-xl="2"
									[ngClass]="getPlatformService.isDesktop ? 'hidden' :''">
									<p class="row-header-1 fw-600 fs-16">Status</p>
								</ion-col>
								<p class="fs-16 fw-500 orange-light-text" *ngIf="status == 'RECEIVED'">
									Received
								</p>
								<p class="fs-16 fw-500 blue-box" *ngIf="status == 'ORDER_INITIATED'">
									Order Received</p>
								<p class="fs-16 fw-500 green-light-box" *ngIf="status == 'SUBMITTED'">
									Submitted
								</p>
								<p class="fs-16 fw-500 red-light-text" *ngIf="status == 'EXPIRED'">
									Expired</p>
								<p class="fs-16 fw-500 grey-light-text" *ngIf="status == 'CLOSED'">
									Closed</p>
								<p class="fs-16 fw-500 orange-light-text"
									*ngIf="status != 'RECEIVED' && status != 'ORDER_INITIATED' &&  status != 'SUBMITTED' && status != 'EXPIRED' && status != 'CLOSED'">
									{{status}}
								</p>
							</ion-col>
							<ion-col size-xs="3.5" size-sm="3.5" size-md="3.5" size-lg="2" size-xl="2">
								<ion-col size-lg="2" size-xl="2" class=""
									[ngClass]="getPlatformService.isDesktop ? 'hidden' :'ml-15'">
									<p class=" fw-600 fs-16 flex justify-end">Subtotal</p>
								</ion-col>
								<p class="fs-16 fw-500 flex justify-end price"
									[ngClass]="getPlatformService.isDesktop ? 'justify-end' :'ml-30'">
									{{commonService.currencySymbol}} {{quoteDetail['sub_total'] ?
									(quoteDetail['sub_total'] | number) : 0 }}
								</p>
							</ion-col>
						</ion-row>

						<div class="divider-line mv-10"></div>
					</div>
				</ng-container>
				<div class="subtotal">
					<div class="right flex">
						<p class="pr-30 light-grey">Total Price: </p>
						<p class="fw-b fs-16">{{commonService.currencySymbol}} {{quoteDetail['total_price'] ?
							(quoteDetail['total_price'] | number) :quoteDetail['total'] ? (quoteDetail['total'] |
							number) : 0}}
						</p>
					</div>
				</div>
			</ion-col>
			<ion-col class="isDesktop" size-xs="12" size-sm="12" size-md="3" size-lg="3" size-xl="3" size-xxl="3">
				<div
					[ngClass]="quoteDetail.cgst_tax_amount && quoteDetail.sgst_tax_percentage ?'order-summary' : 'order-summary-small'">
					<app-order-summary [title]="'Quotation summary'"
						[subTotal]="quoteDetail['sub_total'] ? quoteDetail['sub_total'] : 0"
						[isOrderDetail]="true"></app-order-summary>
					<div class="total-summary mt-5"
						*ngIf="quoteDetail['total_price'] != null || quoteDetail['total'] != null ">

						<ng-container *ngIf="quoteDetail.bid_cgst_tax_percentage ">
							<div class="align-center space-between pt-10">
								<div class="fs-14 fw-500 field">CGST({{quoteDetail.bid_cgst_tax_percentage}}%)
								</div>
								<div class="fs-14 fw-500 right">{{quoteDetail.bid_cgst_tax_amount |
									number}}
								</div>
							</div>
							<div class="align-center space-between pt-10">
								<div class="fs-14 fw-500 field">SGST({{quoteDetail.bid_sgst_tax_percentage}}%)
								</div>
								<div class="fs-14 fw-500 right"> {{quoteDetail.bid_sgst_tax_amount |
									number}}
								</div>
							</div>

							<div class="align-center space-between pt-10" *ngIf="quoteDetail.bid_igst_tax_amount">
								<div class="fs-14 fw-500 field">IGST({{quoteDetail.bid_igst_tax_percentage}}%)
								</div>
								<div class="fs-14 fw-500 right">{{quoteDetail.bid_igst_tax_amount |
									number}}
								</div>
							</div>

						</ng-container>
						<div class="divider-line mv-20" *ngIf="quoteDetail.total_price || quoteDetail.total"></div>
						<div class="align-center space-between" *ngIf="quoteDetail.total_price || quoteDetail.total">
							<div class="fs-16 left field">Total </div>
							<div class="fs-16 fw-b blue-text right"> {{commonService.currencySymbol}}
								{{quoteDetail['total_price'] ?
								(quoteDetail['total_price'] | number) :quoteDetail['total'] ? (quoteDetail['total'] |
								number) : 0}}
							</div>
						</div>
						<div class="align-center space-between mt-5"
							*ngIf="((status == 'RECEIVED' || status == 'ORDER_INITIATED') && isManageQuote != true) ||
                            ((status == 'EXPIRED' || status == 'CLOSED' || status == 'ORDER_INITIATED') && isManageQuote == true)">
							<div class="fs-16 left field">Original Price </div>
							<div class="fs-16 fw-b blue-text right"> {{commonService.currencySymbol}}
								{{quoteDetail.best_deal_price ? (quoteDetail.best_deal_price | number) : 0}}
							</div>
						</div>
						<div class="fs-16 mt-10"
							*ngIf="((status == 'RECEIVED' || status == 'ORDER_INITIATED') && isManageQuote != true) ||
                            ((status == 'EXPIRED' || status == 'CLOSED' || status == 'ORDER_INITIATED') && isManageQuote == true)">
							Using this quote
							<span
								class="fs-16 fw-b blue-text">{{commonService.currencySymbol}}{{quoteDetail.best_deal_price
								- quoteDetail.sub_total}}</span>
							<span *ngIf="isManageQuote"> buyer</span> will be saved
						</div>
						<button [disabled]="isPlaceOrderBtnDisable" class="place-order-btn  mt-15"
							*ngIf="status == 'RECEIVED' && isManageQuote != true"
							(click)="placeOrderConfirmation(quoteDetail)">
							Place Order
						</button>
					</div>
				</div>

			</ion-col>

		</ion-row>
		<ion-row class="mb-10">
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="3" size-xl="3" class="mb-10">
				<ion-label class="fs-16 fw-600">Payment Type</ion-label>
				<div class="fs-16 fw-600 mt-10"><span
						[ngClass]="quoteDetail['credit_days'] ? 'red-light-text' : 'green-light-box'">{{quoteDetail['credit_days']
						? 'On Credit' : 'Instant Payment'}}</span>
					<span class="ml-10" *ngIf="quoteDetail['credit_days']">{{quoteDetail['credit_days']}}
						Days</span>
				</div>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="3" size-xl="3" class="mb-10">
				<ion-label class="fs-16 fw-600">Shipping Address</ion-label>
				<div class="fs-16 fw-500 mt-10 mr-40">{{quoteDetail['shipping_address']}}</div>
			</ion-col>
			<ion-col *ngIf="commonService.userData['isCustomer']" size-xs="12" size-sm="12" size-md="6" size-lg="3"
				size-xl="3" class="mb-10">
				<ion-label class="fs-16 fw-600">Number of Quotations</ion-label>
				<div class="fs-16 fw-500 mt-10">
					<span class="seller-number">{{quoteDetail['seller_count'] ? quoteDetail['seller_count']
						:
						'0'}}</span>
					<span class="ml-5"> {{ quoteDetail['seller_count'] <= 1 ? 'Seller
                        Quoted' : 'Sellers
                        Quoted' }}</span>
				</div>
			</ion-col> </ion-row> <ion-row class="isMobile mt-20">
			<div
				[ngClass]="quoteDetail.cgst_tax_amount && quoteDetail.sgst_tax_percentage ?'order-summary' : 'order-summary-small'">
				<app-order-summary [title]="'Quotation summary'"
					[subTotal]="quoteDetail['sub_total'] ? quoteDetail['sub_total'] : 0"
					[isOrderDetail]="true"></app-order-summary>
				<div class="total-summary mt-5">
					<!-- <div class="align-center space-between pt-5" *ngIf="quoteDetail.cgst_tax_percentage">
						<div class="fs-14 fw-500 field">CGST({{quoteDetail.cgst_tax_percentage}}%)
						</div>
						<div class="fs-14 fw-500 right">{{quoteDetail.cgst_tax_amount |
							number}}
						</div>
					</div>
					<div class="align-center space-between pt-5" *ngIf="quoteDetail.sgst_tax_percentage">
						<div class="fs-14 fw-500 field">SGST({{quoteDetail.sgst_tax_percentage
							?quoteDetail.sgst_tax_percentage : '--'}}%)
						</div>
						<div class="fs-14 fw-500 right"> {{quoteDetail.sgst_tax_amount |
							number}}
						</div>
					</div> -->
					<div *ngIf="quoteDetail.total_price || quoteDetail.total" class="divider-line mv-20"></div>
					<div class="align-center space-between" *ngIf="quoteDetail.total_price ||quoteDetail.total ">
						<div class="fs-16 left field">Total </div>
						<div class="fs-16 fw-b blue-text right"> {{commonService.currencySymbol}}
							{{quoteDetail['total_price'] ?
							(quoteDetail['total_price'] | number) :quoteDetail['total'] ? (quoteDetail['total'] |
							number) : 0}}
						</div>
					</div>
					<div class="align-center space-between mt-5"
						*ngIf="((status == 'RECEIVED' || status == 'ORDER_INITIATED') && isManageQuote != true) ||
					                            ((status == 'EXPIRED' || status == 'CLOSED' || status == 'ORDER_INITIATED') && isManageQuote == true)">
						<div class="fs-16 left field">Original Price </div>
						<div class="fs-16 fw-b blue-text right"> {{commonService.currencySymbol}}
							{{quoteDetail.best_deal_price ? (quoteDetail.best_deal_price | number) : 0}}
						</div>
					</div>
					<div class="fs-16 mt-10"
						*ngIf="((status == 'RECEIVED' || status == 'ORDER_INITIATED') && isManageQuote != true) ||
					                            ((status == 'EXPIRED' || status == 'CLOSED' || status == 'ORDER_INITIATED') && isManageQuote == true)">
						Using this quote
						<span class="fs-16 fw-b blue-text">{{commonService.currencySymbol}}{{quoteDetail.best_deal_price
							- quoteDetail.sub_total}}</span>
						<span *ngIf="isManageQuote"> buyer</span> will be saved
					</div>
					<button [disabled]="isPlaceOrderBtnDisable" class="place-order-btn  mt-15"
						*ngIf="status == 'RECEIVED' && isManageQuote != true"
						(click)="placeOrderConfirmation(quoteDetail)">
						Place Order
					</button>
				</div>
			</div>

		</ion-row>
	</div>
</div>
<div class="quote-detail" *ngIf="isLoading">
	<div class="quote-top">
		<ion-row>
			<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="3" size-xl="3">
				<ion-label class="fs-16 fw-600">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</ion-label>
				<div class="fs-16 fw-500 mt-10">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</div>
			</ion-col>
			<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="3" size-xl="3">
				<ion-label class="fs-16 fw-600">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</ion-label>
				<div class="fs-16 fw-500 mt-10">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</div>
			</ion-col>
			<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="3" size-xl="3">
				<ion-label class="fs-16 fw-600">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</ion-label>
				<div class="fs-16 fw-500 mt-10">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</div>
			</ion-col>

		</ion-row>
	</div>
	<div class="quote-middle mt-20">
		<ion-row class="mb-10">
			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="9" size-xl="9" class="pr-30">
				<div>
					<ion-row class="mb-20 mt-20">
						<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="5" size-xl="5" class="pl-0">
							<div class="product-detail">
								<div class="skeleton-img w-115 h-50">
									<ion-skeleton-text class="skeleton-container w-115" name="lines-sharp-small"
										[animated]="true"></ion-skeleton-text>
								</div>
								<div class="product-description w-115">
									<div class="product-name">
										<ion-skeleton-text class="skeleton-container w-115" name="lines-sharp-small"
											[animated]="true"></ion-skeleton-text>
									</div>
									<div class="product-sku w-115">
										<div class="sku-header w-115">
											<ion-skeleton-text class="skeleton-container w-115" name="lines-sharp-small"
												[animated]="true"></ion-skeleton-text>
										</div>
									</div>

									<div class="product-attribute w-115">
										<ion-skeleton-text class="skeleton-container w-115" name="lines-sharp-small"
											[animated]="true"></ion-skeleton-text>
									</div>
								</div>
							</div>
						</ion-col>

						<ion-col size-lg="2" size-xl="2" class="isDesktop">
							<ion-skeleton-text class="skeleton-container w-115 h-50" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</ion-col>
						<ion-col class="isDesktop" size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
							<ion-skeleton-text class="skeleton-container w-115 h-50" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</ion-col>
						<ion-col class="isDesktop" size-lg="2" size-xl="2">
							<ion-skeleton-text class="skeleton-container w-115 h-50" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</ion-col>
						<ion-col class="isDesktop" size-xs="12" size-sm="12" size-md="12" size-lg="1" size-xl="1">
							<ion-skeleton-text class="skeleton-container w-115 h-50" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</ion-col>
					</ion-row>
					<div class="divider-line mv-10"></div>

				</div>
			</ion-col>
		</ion-row>

		<ion-row class="mb-10">
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="3" size-xl="3" class="mb-10">
				<ion-label class="fs-16 fw-600">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</ion-label>
				<div class="fs-16 fw-500 mt-10"><span>
						<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
							[animated]="true"></ion-skeleton-text>
					</span>
				</div>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="3" size-xl="3" class="mb-10">
				<ion-label class="fs-16 fw-600">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</ion-label>
				<div class="fs-16 fw-500 mt-10 mr-40">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</div>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="3" size-xl="3" class="mb-10">
				<ion-label class="fs-16 fw-600">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</ion-label>
				<div class="fs-16 fw-500 mt-10">
					<ion-skeleton-text class="skeleton-container w-215" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</div>
			</ion-col>
		</ion-row>
	</div>
</div>