import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { ApiService } from '../../services/api-data.service';
import { MasterApiService } from '../../services/master-api.service';
import { ToasterService } from '../../services/toaster.service';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { CommonService } from './../../services/common.service';

declare var google;

export interface AddAddress {
	address_type_id?: number;
	name?: string;
	building_address?: string;
	address?: string;
	state_id?: number;
	district_id?: number;
	taluka_id?: number;
	zip_code?: string;
	gst_number?: string;
	country_id?: number;
	contact_no?: string;
	is_default_billing?: boolean;
	is_default_shipping?: boolean;
	is_active?: boolean;
	latitude?: string;
	longitude?: string;
	other_address_type?: string;
	user_address_id?: number;
	company_address_id?: number;
}
@Component({
	selector: 'app-add-address',
	templateUrl: './add-address.component.html',
	styleUrls: ['./add-address.component.scss'],
})

export class AddAddressComponent implements OnInit {
	addEditAddressForm: FormGroup;
	is_default_billing: boolean = false;
	is_default_shipping: boolean = false;
	addressId: any;
	profileDetails: any;
	isCustomTagsDisabled: boolean = false;
	public subscription = new Subscription();
	talukaList: any = [];
	isSubmitted = false;
	isLoading = false;
	public addressDetails: any = [];
	public validation_messages = {
		location_tag: [
			{ type: 'required', message: 'Please select location tag' },
		],
		company_tag: [
			{ type: 'required', message: 'Please enter company tag' },
		],
		name: [
			{ type: 'required', message: 'Please enter name' },
			{ type: 'invalidString', message: 'Please enter valid name' },
			{ type: 'invalidName', message: 'Please enter valid name' },
			{ type: 'maxlength', message: 'Name should not be more than 50 characters long' }

		],
		building_address: [
			{ type: 'required', message: 'Please enter floor no or block no' },
			{ type: 'invalidString', message: 'Please enter valid floor no or block no' },
			{ type: 'invalidName', message: 'Please enter valid floor no or block no' },
			{ type: 'maxlength', message: 'Floor no or block no should not be more than 50 characters long' }

		],
		address: [
			{ type: 'required', message: 'Please enter address' },
			{ type: 'invalidString', message: 'Please enter valid address' },
			{ type: 'invalidName', message: 'Please enter valid address' },
			{ type: 'maxlength', message: 'Address should not be more than 200 characters long' }

		],
		country: [
			{ type: 'required', message: 'Please select country' },
		],
		state: [
			{ type: 'required', message: 'Please select state' }
		],
		district: [
			{ type: 'required', message: 'Please select district' }
		],
		mobile_no: [
			{ type: 'required', message: 'Please enter mobile no' },
			{ type: 'invalidString', message: 'Please enter valid mobile no' },
			{ type: 'invalidNumber', message: 'Please enter valid mobile no' }
		],
		zipcode: [
			{ type: 'required', message: 'Please enter zipcode' },
			{ type: 'invalidString', message: 'Please enter valid zipcode' },
			{ type: 'invalidZip', message: 'Please enter valid zipcode' },
			{ type: 'maxlength', message: 'Please enter maximum 6 character' },
			{ type: 'minlength', message: 'Please enter minimum 6 character' },
		],
		gst: [
			{ type: 'required', message: 'Please enter GST number' },
			{ type: 'minlength', message: 'GST should be 15 character long' },
			{ type: 'maxlength', message: 'GST should be 15 character long' },
			{ type: 'invalidGST', message: 'Please enter valid GST number' },

		],
	}
	countryList: any = [];

	locationTag: any = [];

	districtList: any = [];
	stateList: any = [];
	isCountryLoading = false;
	isStateLoading = false;
	isDistrictLoading = false;
	isCityLoading = false;
	isTalukaLoading = false;
	stateCount = 0;
	districtCount = 0;
	talukaCount = 0;
	countryCount = 0;
	currentCountryPage = 1;
	country_id = 1;
	state_id = null;
	district_id = null;
	taluka_id = null;
	currentStatePage = 1;
	currentCityPage = 1;
	currentTalukaPage = 1;
	selected_state_id = null;
	selected_district_id = null;
	selected_taluka_id = null;
	isEditAddress = false;

	autocompleteItems: any = [];
	GoogleAutocomplete: any;
	latitude;
	longitude;
	callGoogleApi = true;
	geocoder;
	shipping_address_id;
	isAutoSelected = false;

	selectedAddress: any;
	@Input() from?: any;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private navCtrl: NavController,
		public commonService: CommonService,
		private route: ActivatedRoute,
		private masterApi: MasterApiService,
		private locationService: LocationService,
		private cartService: CartService,
		public modalService: ModalService

	) { }


	ngOnInit() {

		this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
		this.geocoder = new google.maps.Geocoder;
		this.autocompleteItems = [];
		this.getCountry();
		this.getState('IN');
		this.commonService.getUserDetail().then((data) => {
			let details = data
			this.profileDetails = details;
			this.route.paramMap.subscribe((params: ParamMap) => {
				this.addressId = params.get('id');
				this.getAddress().then(() => {
					this.getAddressType();
				});
			});
		});

		setTimeout(() => {
			this.addEditAddressForm = this.initializeAddressForm();
		})

	}

	initializeAddressForm() {

		let gstValidators = [
			Validators.maxLength(15),
			Validators.minLength(15),
			CustomValidator.gstValidator,
		];

		let customer_type = this.commonService.localStorageGet('customer_type');
		let user_type = this.commonService.localStorageGet('user_type');

		if (customer_type !== 'INDIVIDUAL') {
			gstValidators.push(Validators.required);
		}

		return this.formBuilder.group({
			location_tag: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			company_tag: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			name: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.alphabetValidator, CustomValidator.noWhiteSpaceValidator]),
			}),
			building_address: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.noWhiteSpaceValidator]),
			}),
			address: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(300), CustomValidator.noWhiteSpaceValidator]),
			}),
			country: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			state: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			district: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			taluka: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			mobile_no: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator, CustomValidator.noWhiteSpaceValidator]),
			}),
			zipcode: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.zipCodeValidator, CustomValidator.noWhiteSpaceValidator, Validators.maxLength(6), Validators.minLength(6)]),
			}),
			gst: new FormControl(null, {
				validators: Validators.compose(gstValidators),
			})
		})
	}

	phoneClick(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	get f1() { return this.addEditAddressForm.controls; }


	isDuplicateAddress(address, is_edit, addressId?) {
		// Create a shallow copy of the address details array to avoid mutating the original
		let addressArray = [...this.addressDetails];
		let backupArray = null;

		if (!addressArray || addressArray.length === 0) {
			console.log('Address array is empty.');
			return false;
		}

		const customer_type = this.commonService.localStorageGet('customer_type');

		if (is_edit && addressId) {
			// Exclude the current address from the duplicate check
			addressArray.forEach((element, index) => {
				if (element.address_id == addressId) {
					addressArray.splice(index, 1);
				}
			});

			backupArray = [...addressArray];
		}

		else {
			backupArray = [...addressArray];
		}

		// Perform the duplicate check
		if (customer_type !== 'INDIVIDUAL') {
			// Check for duplicate with GST number included
			return addressArray.some(
				existingAddress =>
					existingAddress.address_type_id === address.address_type_id &&
					existingAddress.address === address.address &&
					existingAddress.gst_number === address.gst_number
			);
		} else {
			// Check for duplicate without GST number for 'INDIVIDUAL'
			return addressArray.some(
				existingAddress =>
					existingAddress.address_type_id === address.address_type_id &&
					existingAddress.address === address.address
			);
		}
	}


	addAddress() {
		this.isSubmitted = false;
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}
		if (this.addEditAddressForm.invalid) {
			this.isSubmitted = true;
			this.commonService.scrollToTop();
			return;
		}
		if (this.isAutoSelected) {
			return;
		}


		let addAddress: AddAddress = {};

		addAddress.address_type_id = this.addEditAddressForm.value.location_tag;
		addAddress.name = this.addEditAddressForm.value.name;
		addAddress.building_address = this.addEditAddressForm.value.building_address;
		addAddress.address = this.addEditAddressForm.value.address;
		addAddress.state_id = this.addEditAddressForm.value.state;
		addAddress.district_id = this.addEditAddressForm.value.district;
		addAddress.zip_code = this.addEditAddressForm.value.zipcode;

		addAddress.country_id = this.addEditAddressForm.value.country;
		if (this.addEditAddressForm.value.taluka) {
			addAddress.taluka_id = this.addEditAddressForm.value.taluka;
		}
		addAddress.contact_no = this.addEditAddressForm.value.mobile_no;
		addAddress.is_default_billing = this.is_default_billing;
		addAddress.is_default_shipping = this.is_default_shipping;
		addAddress.is_active = true;
		addAddress.latitude = this.locationService.latitude;
		addAddress.longitude = this.locationService.longitude;
		if (this.addEditAddressForm.value.company_tag) {
			addAddress.other_address_type = this.addEditAddressForm.value.company_tag;
		}

		if (this.addressId) {
			if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
				addAddress.company_address_id = +this.addressId;
				if (this.selectedAddress.gst_number != this.f1.gst.value) {
					addAddress.gst_number = this.addEditAddressForm.value.gst == "null" || this.addEditAddressForm.value.gst == "" ||
						this.addEditAddressForm.value.gst == " " || !this.addEditAddressForm.value.gst ? null : this.addEditAddressForm.value.gst;
				}
			} else {
				addAddress.user_address_id = +this.addressId;
				if (this.selectedAddress.gst_number != this.f1.gst.value) {
					addAddress.gst_number = this.addEditAddressForm.value.gst == "null" || this.addEditAddressForm.value.gst == "" ||
						this.addEditAddressForm.value.gst == " " || !this.addEditAddressForm.value.gst ? null : this.addEditAddressForm.value.gst;
				}
			}
		}
		else {
			addAddress.gst_number = this.addEditAddressForm.value.gst == "null" || this.addEditAddressForm.value.gst == "" ||
				this.addEditAddressForm.value.gst == " " || !this.addEditAddressForm.value.gst ? null : this.addEditAddressForm.value.gst;
		}

		if (this.addressId) {
			if (this.isDuplicateAddress(addAddress, true, this.addressId)) {
				this.toasterService.errorToast('Duplicate addresses are not allowed on edit time.');
				return;
			}
		}
		else {
			if (this.isDuplicateAddress(addAddress, false)) {
				this.toasterService.errorToast('Duplicate addresses are not allowed on add time.');
				return;
			}
		}
		this.isLoading = true;

		if (!this.addressId) {
			this.subscription.add(
				this.dataService.post(URLS.addAddress, addAddress).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data']) {
							this.addEditAddressForm.reset();
							if (this.from == 'modal') {
								this.modalService.dismissModal({ data: data });
							}
							else {
								this.commonService.scrollToTop();
								this.toasterService.successToast(data['message']);
								this.navCtrl.navigateBack("/home/account-details/addresses");
							}

						}
						this.isLoading = false;
					}
					else {
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)

		} else {
			this.subscription.add(
				this.dataService.put(URLS.editAddress, addAddress).subscribe(data => {
					if (data['code'] == 200) {
						this.addEditAddressForm.reset();
						this.navCtrl.navigateBack("/home/account-details/addresses");
						this.commonService.scrollToTop();
						this.toasterService.successToast(data['message']);
						this.isLoading = false;
					}
					else {
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		}


	}

	getCountry(shortCode?) {
		if (shortCode) {
			return new Promise((resolve, reject) => {
				this.isCountryLoading = true;
				let param = {
					short_name: shortCode
				}
				this.countryList = [];
				this.masterApi.getCountry(param).then((data) => {
					this.isCountryLoading = false;
					this.countryList = this.countryList.concat(data['data']['countriesList']);
					if (this.countryList && this.countryList.length) {
						this.f1.country.setValue(this.countryList[0].country_id);
					}
					resolve(data);
				}, error => {
					reject(error);
					this.countryList = [];
				});
			})
		} else {
			return new Promise((resolve, reject) => {
				this.isCountryLoading = true;
				let param = {
					short_name: "IN"
				}
				this.countryList = [];
				this.masterApi.getCountry(param).then((data) => {
					this.isCountryLoading = false;
					this.countryList = this.countryList.concat(data['data']['countriesList']);
					if (this.countryList && this.countryList.length) {
						this.f1.country.setValue(this.countryList[0].country_id);
					}
					resolve(data);
				}, error => {
					reject(error);
					this.countryList = [];
				});
			})
		}
	}

	getState(shortCode?) {
		return new Promise((resolve, reject) => {
			let params = {
				short_name: shortCode
			}

			this.isStateLoading = true;
			this.stateList = [];
			this.masterApi.getState(params).then((data) => {
				this.isStateLoading = false;
				this.stateList = this.stateList.concat(data['data']['statesList']);
				this.stateCount = data['data']['total_count'];
				if (this.stateList && this.stateList.length) {
					this.f1.state.setValue(this.stateList[0].state_id);
					this.state_id = this.stateList[0].state_id
				}
				resolve(data);
			}, error => {
				reject(error);
				this.isStateLoading = false;
				this.stateList = [];
				const params = {
					country_id: this.country_id
				}
				this.masterApi.getState(params).then((data) => {
					this.isStateLoading = false;
					this.stateList = this.stateList.concat(data['data']['statesList']);
					this.stateCount = data['data']['total_count'];
					if (this.selected_state_id) {
						this.f1.state.setValue(this.selected_state_id)
					}
					resolve(data);
				}, error => {
					reject(error);
					this.isStateLoading = false;
					this.stateList = [];
				});
			});
		})

	}


	getDistrict(shortCode?) {
		if (shortCode) {
			return new Promise((resolve, reject) => {
				let params = {
					short_name: shortCode
				}
				this.districtList = [];
				this.masterApi.getDistrict(params).then((data) => {
					this.districtList = this.districtList.concat(data['data']['districtList']);
					this.districtCount = data['data']['total_count'];
					if (this.districtList && this.districtList.length) {
						this.f1.district.setValue(this.districtList[0].district_id);
						this.district_id = this.districtList[0].district_id;
						this.getTaluka(this.locationService.taluka_shortname, this.district_id);
					}
					resolve(data);
				}, error => {
					reject(error);
					this.districtList = [];
					setTimeout(() => {
						if (this.state_id) {
							return new Promise((resolve, reject) => {
								let params = {
									state_id: this.state_id
								}
								this.masterApi.getDistrict(params).then((data) => {
									this.districtList = this.districtList.concat(data['data']['districtList']);
									this.districtCount = data['data']['total_count'];
									if (this.selected_district_id) {
										this.f1.district.setValue(this.selected_district_id)
									}
									resolve(data);
								}, error => {
									reject(error);

								});
							})
						}
					}, 500);

				});
			})
		} else {
			setTimeout(() => {
				if (this.state_id) {
					return new Promise((resolve, reject) => {
						let params = {
							state_id: this.state_id
						}
						this.districtList = [];
						this.masterApi.getDistrict(params).then((data) => {
							this.districtList = this.districtList.concat(data['data']['districtList']);
							this.districtCount = data['data']['total_count'];
							if (this.selected_district_id) {
								this.f1.district.setValue(this.selected_district_id)
							}
							resolve(data);
						}, error => {
							reject(error);

						});
					})
				}
			}, 500);
		}
	}

	getTaluka(shortCode?, district_id?) {
		if (shortCode) {
			return new Promise((resolve, reject) => {
				let params = {
					short_name: shortCode,
					district_id: district_id
				}
				this.talukaList = [];
				this.masterApi.getTaluka(params).then((data) => {
					this.talukaList = this.talukaList.concat(data['data']['talukaList']);
					this.talukaCount = data['data']['total_count'];
					if (this.talukaList && this.talukaList.length) {
						this.f1.taluka.setValue(this.talukaList[0].taluka_id);
					}
					resolve(data);
				}, error => {
					reject(error)
					this.talukaList = [];
					setTimeout(() => {
						if (this.district_id) {
							const params = {
								district_id: this.district_id
							}
							this.masterApi.getTaluka(params).then((data) => {
								this.talukaList = this.talukaList.concat(data['data']['talukaList']);
								this.talukaCount = data['data']['total_count'];
								if (this.selected_taluka_id) {
									this.f1.taluka.setValue(this.selected_taluka_id)
								}
								resolve(data);
							}, error => {
								reject(error)
								this.talukaList = [];
							});
						}
					}, 700);
				});
			})
		} else {
			setTimeout(() => {
				if (this.district_id) {
					return new Promise((resolve, reject) => {
						let params = {
							district_id: this.district_id
						}
						this.talukaList = [];
						this.masterApi.getTaluka(params).then((data) => {
							this.talukaList = this.talukaList.concat(data['data']['talukaList']);
							this.talukaCount = data['data']['total_count'];
							if (this.selected_taluka_id) {
								this.f1.taluka.setValue(this.selected_taluka_id)
							}
							resolve(data);
						}, error => {
							reject(error)
							this.talukaList = [];
						});
					})
				}
			}, 700);
		}
	}

	countryChange(data) {
		this.country_id = data.country_id;
		this.getState('IN');
	}

	stateChange(data) {
		this.state_id = data.state_id;
		if (this.f1.district.value) {
			this.f1.district.reset();
		}
		if (this.f1.taluka.value) {
			this.f1.taluka.reset();
		}
		if (this.f1.zipcode.value) {
			this.f1.zipcode.reset();
		}
		if (data.state_id) {
			return new Promise((resolve, reject) => {
				let params = {
					state_id: data.state_id
				}
				this.districtList = [];
				this.masterApi.getDistrict(params).then((data) => {
					this.districtList = this.districtList.concat(data['data']['districtList']);
					this.districtCount = data['data']['total_count'];
					resolve(data);
				}, error => {
					reject(error);
					this.districtList = [];
				});
			})
		}
	}

	districtChange(data) {
		this.district_id = data.district_id;
		if (this.f1.taluka.value) {
			this.f1.taluka.reset();
		}
		if (this.f1.zipcode.value) {
			this.f1.zipcode.reset();
		}
		if (data.district_id) {
			return new Promise((resolve, reject) => {
				let params = {
					district_id: data.district_id
				}
				this.talukaList = [];
				this.masterApi.getTaluka(params).then((data) => {
					this.talukaList = this.talukaList.concat(data['data']['talukaList']);
					this.talukaCount = data['data']['total_count'];
					resolve(data);
				}, error => {
					reject(error)
					this.talukaList = [];
				});
			})
		}
	}

	talukaChange(data) {
		if (data) {
			this.taluka_id = data.taluka_id ? data.taluka_id : null;
		}
		if (this.f1.zipcode.value) {
			this.f1.zipcode.reset();
		}
	}

	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	getAddressType() {
		return new Promise((resolve, reject) => {
			this.callGoogleApi = false;
			const params = {
				user_type: 'CUSTOMER',
				customer_type: this.commonService?.userData?.customer_type == 'ENTERPRISE' ? 'ENTERPRISE' : 'INDIVIDUAL'
			}
			this.subscription.add(
				this.dataService.get(URLS.getAddressType, params).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data'].length > 0) {
							let addressType = data['data'];
							let addressData = [];
							addressType.forEach(element => {
								let obj = {
									name: element.address_type,
									id: element.address_type_id,
									is_active: element.is_active,
									disabled: false
								}
								addressData.push(obj);
							});

							this.locationTag = addressData
							if (this.addressDetails && this.addressDetails.length) {
								this.addressDetails.forEach(element => {
									if (element.address_type == 'HEADQUARTER') {
										if (this.locationTag && this.locationTag.length) {
											this.locationTag.forEach(locationElement => {
												if (locationElement.name == 'HEADQUARTER') {
													locationElement['disabled'] = true;
												}
											});
										}
									}
								});
							}
							resolve(this.locationTag)
						}
						this.isLoading = false;
					}
					else {
						reject(data);
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					reject(error);
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		})
	}


	getAddress() {
		return new Promise((resolve, reject) => {
			this.isLoading = true;
			let params: any;
			if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
				params = {
					"user_type": "CUSTOMER",
					"company_id": this.profileDetails.company_id
				}
			}
			this.subscription.add(
				this.dataService.get(URLS.getAddress, params).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data']) {
							let addessData = data['data']['addressList'];
							this.addressDetails = data['data']['addressList'];
							this.addressDetails.forEach(element => {
								if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
									element.address_id = element.company_address_id;
								} else {
									element.address_id = element.user_address_id;
								}
							});
							if (this.addressId) {
								let address: any;
								if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
									address = addessData.find((element) => element.company_address_id == this.addressId);
								} else {
									address = addessData.find((element) => element.user_address_id == this.addressId);
								}
								this.selected_state_id = address.state_id;
								this.selected_district_id = address.district_id;
								this.selected_taluka_id = address.taluka_id;
								this.selectedAddress = address;
								if (address) {
									this.getCountry("IN").then((country) => {
										this.f1.country.setValue(address.country_id ? address.country_id : null);
									});
									if (address.state_code) {
										this.getState(address.state_code).then((state) => {
											this.f1.state.setValue(address.state_id ? address.state_id : null);
										});
									}
									if (address.district_code) {
										this.getDistrict(address.district_code).then((district) => {
											this.f1.district.setValue(address.district_id ? address.district_id : null);
										});
									}
									if (address.taluka_code) {
										this.getTaluka(address.taluka_code).then((taluka) => {
											this.f1.taluka.setValue(address.taluka_id ? address.taluka_id : null);
										});
									}
									this.f1.location_tag.setValue(address.address_type_id ? address.address_type_id : null);
									if (address.address_type_id == 1) this.f1.location_tag.disable();
									this.f1.company_tag.setValue(address.other_address_type ? address.other_address_type : null);
									this.f1.name.setValue(address.name ? address.name : address.warehouse_name);
									this.f1.address.setValue(address.address ? address.address : null);
									this.f1.building_address.setValue(address.building_address ? address.building_address : null);
									this.f1.mobile_no.setValue(address.contact_no ? address.contact_no : null);
									this.f1.zipcode.setValue(address.zip_code ? address.zip_code : null);
									this.f1.gst.setValue(address.gst_number ? address.gst_number : null);
									this.is_default_billing = address.is_default_billing ? true : false;
									this.is_default_shipping = address.is_default_shipping ? true : false;
									this.locationService.latitude = address.latitude;
									this.locationService.longitude = address.longitude;
									this.isEditAddress = true;
									if (this.profileDetails.company_id) {
										if (this.f1.address.value) {
											this.getPlaceAutocomplete(null, this.f1.address.value);
										}
									}
								}
							}
							resolve(this.addressDetails)
						}
						this.isLoading = false;
					}
					else {
						reject(data);
						this.isEditAddress = false;
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					reject(error);
					this.isEditAddress = false;
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		})
	}

	cancel() {
		if (this.from == 'modal') {
			this.modalService.dismissModal();
		} else {
			this.navCtrl.navigateBack(['/home/account-details/addresses'])
		}
	}


	async getPlaceAutocomplete(val, addressVal?) {
		let inputValue: any;
		if (val) {
			inputValue = val.target.value;
		} else {
			inputValue = addressVal;
			this.callGoogleApi = true;
		}
		if (inputValue.trim() === '') {
			this.autocompleteItems = [];
			this.addEditAddressForm.patchValue({
				address: null
			});
			return;
		}
		if (this.callGoogleApi) {
			this.f1.country.reset();
			this.f1.state.reset();
			this.f1.district.reset();
			this.f1.taluka.reset();
			if (!this.isEditAddress) {
				this.f1.zipcode.reset();
			}
			this.countryList = [];
			this.stateList = [];
			this.districtList = [];
			this.talukaList = [];
			await this.locationService.getPlaceAutocomplete(this.GoogleAutocomplete, inputValue);
			this.autocompleteItems = this.locationService.autocompleteItems;

			if (this.autocompleteItems.length > 0) {
				this.isAutoSelected = false;
			};

			if (this.autocompleteItems.length > 0 && addressVal) {
				this.selectSearchResult(this.autocompleteItems[0], this.addEditAddressForm.controls.address)
			}

		} else {
			setTimeout(() => {
				this.callGoogleApi = true;
			}, 500);
		}

	}

	/**
  * SELECT SPECIF LOCATION AND REDIRECTING ON SAME THROUGH MAP
 */
	async selectSearchResult(item, formcontrolName) {
		this.callGoogleApi = false;
		this.isAutoSelected = false;
		setTimeout(() => {
			this.autocompleteItems = [];
		});
		this.locationService.getselectedAutoMap(this.geocoder, item, formcontrolName).then(data => {
			if (this.locationService.country_shortname) {
				this.getCountry(this.locationService.country_shortname);
			} else {
				this.getCountry(null);
			}
			if (this.locationService.state_shortname) {
				this.getState(this.locationService.state_shortname);
			}
			if (this.locationService.district_shortname) {
				this.getDistrict(this.locationService.district_shortname);
			} else {
				this.getDistrict(null);
			}
			if (this.locationService.zipcode_shortname) {
				this.f1.zipcode.setValue(this.locationService.zipcode_shortname);
			}
		})

	}

	addressChange() {
		this.isAutoSelected = true;
		this.isEditAddress = false;
	}

	toggleCheckbox(toggleText: string) {
		if (toggleText == "shipping")
			this.is_default_shipping = !this.is_default_shipping;

		if (toggleText == "billing")
			this.is_default_billing = !this.is_default_billing;
	}

	restrictSpecialChar(event) {
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57));
	}
}
