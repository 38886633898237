import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from '../../services/modal.service';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
import { CustomValidator } from './../../_common/customvalidators';
import { profileTypeEnum } from './../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';

export interface CustomerRegisteration {
	first_name?: string;
	last_name?: string;
	email?: string;
	customer_type?: string;
	country_code?: string;
	mobile_no?: string;
	gst_number?: string;
	password?: string;
	platform?: string;
	company_name?: string;
	company_address?: string;
	latitude?: string;
	longitude?: string;
	state_id?: number;
	district_id?: number;
	taluka_id?: number;
	zip_code?: string;
	country_id?: number;
}

@Component({
	selector: 'app-registration',
	templateUrl: './registration.component.html',
	styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
	public registrationForm: FormGroup;
	public subscription = new Subscription();
	public locationData: any;
	isLoading = false;
	passwordType = 'password';
	profileEnum = profileTypeEnum;
	confirmPasswordType = 'password';
	fromPage: any;
	tabList = [
		{
			"name": 'Individual Buyer',
			"id": 1,
			"value": "INDIVIDUAL"
		},
		{
			"name": 'Enterprise Buyer',
			"id": 2,
			"value": "ENTERPRISE"
		},

	]
	registerationType = this.profileEnum.Individual;
	public validation_messages = {
		first_name: [
			{ type: 'required', message: 'Please enter first name' },
			{ type: 'invalidName', message: 'Please enter a valid first name' },
			{ type: 'maxlength', message: 'First name cannot be more than 50 character' },
		],
		last_name: [
			{ type: 'required', message: 'Please enter last name' },
			{ type: 'invalidName', message: 'Please enter a valid last name' },
			{ type: 'maxlength', message: 'Last name cannot be more than 50 character' },
		],
		email: [
			{ type: 'required', message: 'Please enter email' },
			{ type: 'invalidEmail', message: 'Enter valid email id' },
		],
		phone_no: [
			{ type: 'required', message: 'Please enter mobile number' },
			{ type: 'invalidNumber', message: 'Enter valid mobile number' }
		],
		password: [
			{ type: 'required', message: 'Please enter password' },
			{ type: 'invalidPassword', message: 'Password should be a combination of alphanumeric with a minimum of 8 characters and maximum of 12 characters with one special character and one capital letter' },
		],
		confirm_password: [
			{ type: 'required', message: 'Please re-enter your password' },
			{ type: 'passMismatch', message: 'Password mismatch' },
		],
		gst: [
			{ type: 'required', message: 'Please enter GST number' },
			{ type: 'minlength', message: 'GST should be 15 character long' },
			{ type: 'maxlength', message: 'GST should be 15 character long' },
			{ type: 'invalidGST', message: 'Please enter valid GST number' },

		],
		termsCondition: [
			{ type: 'required', message: 'Please accept terms and conditions' },
		],

	}
	fieldData = {
		"name": "Name",
		"email": "Email",
		"mobile_no": "Phone number"
	}
	userDetail = {};
	isLogin: boolean = false;
	isBecomeCustomer = false;
	userAgent: any

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private navCtrl: NavController,
		private platform: Platform,
		private locationservice: LocationService,
		private modalService: ModalService,

	) {

		let state = this.router.getCurrentNavigation().extras.state;
		if (state && state.locationData) {
			this.locationData = state.locationData
		}
	}

	ngOnInit() {
		this.registrationForm = this.initializeForm();
		this.registrationForm.reset();
		this.registrationForm.controls['termsCondition'].setValue(false);

		this.commonService.isLoggedIn = this.commonService.localStorageGet('accessToken') || this.commonService.accessToken ? true : false;
		this.commonService.eventObservable.subscribe((data: any) => {
			if (data.event == 'isBecomeCustomer') {
				this.isBecomeCustomer = data['data'];
				if (this.isBecomeCustomer) {
					this.commonService.localStorageSet("becomeCustomer", this.isBecomeCustomer);
				}
			}
		})
		this.isBecomeCustomer = this.commonService.localStorageGet('isBecomeCustomer') ? true : false;
		if (this.isBecomeCustomer) {
			this.getUserDetail();
		}
	}

	getUserDetail() {
		this.commonService.getUserDetail().then((data) => {
			this.registerationType = data['seller_type'];
			this.registrationForm = this.initializeForm(data, true);
			this.registrationForm.get('password').clearValidators();
			this.registrationForm.get('confirm_password').clearValidators();
			this.registrationForm.controls['termsCondition'].setValue(false);
			if (this.registerationType == this.profileEnum.Enterprise) {
				this.registrationForm.get('company_name').setValidators([Validators.required]);
				this.registrationForm.get('hq_location').setValidators([Validators.required]);
				this.registrationForm.get('country').setValidators([Validators.required]);
				this.registrationForm.get('state').setValidators([Validators.required]);
				this.registrationForm.get('district').setValidators([Validators.required]);
				this.registrationForm.get('zipcode').setValidators([Validators.required, Validators.maxLength(6), Validators.minLength(6)]);
				this.registrationForm.get('gst').setValidators([Validators.required, Validators.maxLength(15), Validators.minLength(15), CustomValidator.gstValidator]);
				this.registrationForm.get('gst').updateValueAndValidity();
				this.registrationForm.get('zipcode').updateValueAndValidity();
			}
			else {
				this.registrationForm.get('company_name').clearValidators();
				this.registrationForm.get('hq_location').clearValidators();
				this.registrationForm.get('country').clearValidators();
				this.registrationForm.get('state').clearValidators();
				this.registrationForm.get('district').clearValidators();
				this.registrationForm.get('zipcode').clearValidators();
				this.registrationForm.get('zipcode').updateValueAndValidity();
				this.registrationForm.get('gst').clearValidators();
				this.registrationForm.get('gst').updateValueAndValidity();

			}
		}, (error: any) => {
			this.toasterService.errorToast(error['message']);
		});
	}

	ionViewWillEnter() {
		this.subscription = new Subscription();
		this.registrationForm = this.initializeForm();
		this.registrationForm.reset();
		this.registrationForm.controls['termsCondition'].setValue(false);

	}

	initializeForm(data?, isDisable?) {
		return this.formBuilder.group({
			first_name: new FormControl({ value: data ? data['first_name'] : null, disabled: isDisable ? isDisable : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
			}),
			last_name: new FormControl({ value: data ? data['last_name'] : null, disabled: isDisable ? isDisable : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
			}),
			email: new FormControl({ value: data ? data['email'] : null, disabled: isDisable ? isDisable : false }, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			phone_no: new FormControl({ value: data ? data['mobile_no'] : null, disabled: isDisable ? isDisable : false }, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator]),
			}),
			gst: new FormControl({ value: null, disabled: false }, {
				validators: Validators.compose([Validators.required, Validators.minLength(15), Validators.maxLength(15), CustomValidator.gstValidator]),
			}),
			password: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
			}),
			confirm_password: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.confirmPassword]),
			}),
			termsCondition: new FormControl(true, {
				validators: Validators.compose([Validators.requiredTrue]),
			}),
			company_name: new FormControl({ value: data ? data['company_name'] : null, disabled: data && data['company_name'] ? true : false }, {
				validators: Validators.compose([]),
			}),
			hq_location: new FormControl('', {
				validators: Validators.compose([]),
			}),
			country: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			state: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			district: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			taluka: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			zipcode: new FormControl(null, {
				validators: Validators.compose([Validators.maxLength(6), Validators.minLength(6)]),
			}),
		});
	}

	phoneClick(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	onSubmit() {
		let customNotification = {};
		for (const i in this.registrationForm.controls) {
			this.registrationForm.controls[i].markAsDirty();
			this.registrationForm.controls[i].updateValueAndValidity();
			this.registrationForm.controls[i].markAsTouched();
		}
		if (this.registerationType === this.profileEnum.Enterprise) {
			this.registrationForm.get('gst').setValidators([Validators.required, Validators.maxLength(15), Validators.minLength(15), CustomValidator.gstValidator]);
			this.registrationForm.get('gst').updateValueAndValidity();
		}
		else {
			this.registrationForm.get('gst').clearValidators();
			this.registrationForm.get('gst').updateValueAndValidity();
		}
		if (this.registrationForm.invalid) {
			this.commonService.scrollToTop();
			return
		}
		if (this.commonService.isAutoSelected) {
			return;
		}
		let registerData: CustomerRegisteration = {};
		registerData.first_name = this.registrationForm.value.first_name?.trim();
		registerData.last_name = this.registrationForm.value.last_name?.trim();
		registerData.email = this.registrationForm.value.email?.toLowerCase();
		registerData.password = this.registrationForm.value.password;
		registerData.mobile_no = this.registrationForm.value.phone_no;
		if (this.platform.is('desktop')) {
			registerData.platform = "WEB";
		} else if (this.platform.is('ios')) {
			registerData.platform = "IOS";
		} else if (this.platform.is('android')) {
			registerData.platform = "ANDROID";
		}
		registerData.country_code = this.commonService.countryCode;
		registerData.customer_type = this.registerationType == this.profileEnum.Enterprise ? 'ENTERPRISE' : 'INDIVIDUAL'

		//If user type is enterprise customer
		if (this.registerationType === this.profileEnum.Enterprise) {
			registerData.company_name = this.registrationForm.value.company_name?.trim();
			registerData.company_address = this.registrationForm.value.hq_location;
			registerData.latitude = this.locationservice.latitude
			registerData.longitude = this.locationservice.longitude
			registerData.country_id = this.registrationForm.value.country
			registerData.state_id = this.registrationForm.value.state
			registerData.district_id = this.registrationForm.value.district
			if (this.registrationForm.value.taluka) {
				registerData.taluka_id = this.registrationForm.value.taluka
			}
			registerData.zip_code = this.registrationForm.value.zipcode
			registerData.gst_number = this.registrationForm.value.gst
		}
		let encryptedData = this.commonService.encryptData(JSON.stringify(registerData));
		let param = {
			company_id: parseInt(this.commonService.localStorageGet('company_id')),
			customer_type: this.registerationType == this.profileEnum.Enterprise ? 'ENTERPRISE' : 'INDIVIDUAL'
		};
		if (this.registerationType === this.profileEnum.Enterprise) {
			param['address'] = this.registrationForm.value.hq_location;
			param['latitude'] = this.locationservice.latitude;
			param['longitude'] = this.locationservice.longitude
			param['country_id'] = this.registrationForm.value.country;
			param['state_id'] = this.registrationForm.value.state;
			param['district_id'] = this.registrationForm.value.district;
			param['zip_code'] = this.registrationForm.value.zipcode;
			param['gst_number'] = this.registrationForm.value.gst;
			if (this.registrationForm.value.taluka) {
				param['taluka_id'] = this.registrationForm.value.taluka;
			}
		}
		this.isLoading = true;
		if (this.isBecomeCustomer) {
			this.subscription.add(
				this.dataService.post(URLS.becomeASCustomer, param).subscribe(data => {
					this.isLoading = false;
					if (data['code'] == 200) {
						this.registrationForm.reset();
						this.commonService.setEvent('currentProfile', this.profileEnum.Customer);
						this.commonService.localStorageSet('currentProfile', this.profileEnum.Customer);
						this.commonService.setEvent('profileType', this.registerationType);
						this.commonService.localStorageSet('profileType', this.registerationType);
						this.commonService.setEvent('profileData', 'true');
						let userData = data['data'];
						this.commonService.localStorageSet('uuid', userData.uuid ? userData.uuid : '');
						let userResponseInfo = {
							user_type: data['data']['user']['user_type'] ? data['data']['user']['user_type'] : '',
							customer_type: data['data']['user']['customer_type'] ? data['data']['user']['customer_type'] : '',
							seller_type: data['data']['user']['seller_type'] ? data['data']['user']['seller_type'] : '',
							user_profile: data['data']['user']['seller_type'] != 'NONE' ? data['data']['user']['seller_type'] : data['data']['user']['customer_type'],
							approval_status: data['data']['user']['approval_status'] ? data['data']['user']['approval_status'] : '',
							isSeller: false,
							name: data['data']['user']['first_name'] + ' ' + data['data']['user']['last_name'],
							isCustomer: data['data']['user']['user_type'] == 'BOTH' || data['data']['user']['user_type'] == this.profileEnum.Customer ? true : false,

						}
						let type = this.registerationType == this.profileEnum.Individual ? "Individual" : "Enterprise";
						this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));
						this.commonService.userData = userResponseInfo;
						// this.navCtrl.navigateRoot(['/home/account-details']);
						this.navCtrl.navigateRoot(['/home/document-registration'], { animated: false });
						this.commonService.callmenuData();
						if (data['data']['user']['user_type'] != 'INDIVIDUAL') {
							customNotification['message'] = data['message'];
							customNotification['type'] = 'warning';
							setTimeout(() => {
								this.commonService.setEvent('alertMessage', customNotification);
							}, 500);
						}

					}
					else {
						this.toasterService.errorToast(data['message'], 'top');
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		}
		else {
			this.fromPage = "registration";
			let platformType;

			if (this.platform.is('desktop')) {
				platformType = "WEB";
			} else if (this.platform.is('ios')) {
				platformType = "IOS";
			} else if (this.platform.is('android')) {
				platformType = "ANDROID";
			} else {
				platformType = "MOBILE";
			}
			let otpParam = {
				"mobile_no": registerData.mobile_no,
				"purpose_type": "REGISTRATION",
				"platform": platformType,
				"email": registerData.email,
				"user_type": "CUSTOMER",
				"request_type": this.registerationType == this.profileEnum.Enterprise ? 'ENTERPRISE' : 'INDIVIDUAL'
			};
			if (this.registerationType == this.profileEnum.Enterprise) {
				otpParam["reference_type"] = "ENTERPRISE_USERS"
				otpParam["company_name"] = registerData.company_name
			}
			this.subscription.add(
				this.dataService.post(URLS.generateOtp, otpParam).subscribe(data => {
					this.isLoading = false;
					if (data['code'] == 200) {
						this.commonService.setEvent('currentProfile', this.profileEnum.Customer);
						this.commonService.localStorageSet('currentProfile', this.profileEnum.Customer);
						this.commonService.setEvent('profileType', this.registerationType);
						this.commonService.localStorageSet('profileType', this.registerationType);
						this.commonService.localStorageSet('customer_registration', encryptedData);
						this.commonService.localStorageSet('registration_type', "customer");
						let userData = data['data'];
						let encryptedUserDetails = this.commonService.encryptData(JSON.stringify(userData));
						this.commonService.localStorageSet('user_details', encryptedUserDetails);
						this.fromPage = "registration";
						this.commonService.localStorageSet('uuid', userData.uuid ? userData.uuid : '');
						this.registrationForm.reset();
						this.toasterService.successToast(data['message'], 'top');
						this.navCtrl.navigateForward('/two-factor-authentication', { queryParams: { fromPage: this.fromPage }, state: { locationData: this.locationData } });
					}
					else {
						this.toasterService.errorToast(data['message'], 'top');
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)
		}

	}

	restrictSpecialChar(event) {
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57));
	}

	async termsConditions(event?) {
	}

	userTypeChange(val) {
		this.registerationType = val;
		if (this.registerationType == this.profileEnum.Enterprise) {
			this.registrationForm.get('company_name').setValidators([Validators.required]);
			this.registrationForm.get('hq_location').setValidators([Validators.required]);
			this.registrationForm.get('country').setValidators([Validators.required]);
			this.registrationForm.get('state').setValidators([Validators.required]);
			this.registrationForm.get('district').setValidators([Validators.required]);
			this.registrationForm.get('taluka').setValidators([Validators.required]);
			this.registrationForm.get('zipcode').setValidators([Validators.required, Validators.maxLength(6), Validators.minLength(6)]);
			this.registrationForm.get('zipcode').updateValueAndValidity();
		}
		else {
			this.registrationForm.get('company_name').clearValidators();
			this.registrationForm.get('hq_location').clearValidators();
			this.registrationForm.get('country').clearValidators();
			this.registrationForm.get('state').clearValidators();
			this.registrationForm.get('district').clearValidators();
			this.registrationForm.get('taluka').clearValidators();
			this.registrationForm.get('zipcode').clearValidators();
			this.registrationForm.get('zipcode').updateValueAndValidity();
		}
		this.registrationForm.reset();
		this.registrationForm.controls['termsCondition'].setValue(false);

	}

	login() {
		this.navCtrl.navigateRoot(['/login']);
	}

	goBack() {
		window.history.back();
	}

	footLinkNavigations(data) {
		switch (data) {
			case 'privacy_policy':
				this.navCtrl.navigateForward('/privacy-policy', { animated: false });
				break;
			case 'terms_of_use':
				this.navCtrl.navigateForward('/terms-of-use', { animated: false });
				break;
		}
	}

	async termsPopUp() {
		let obj = {
			component: TermsOfUseComponent,
			backdropDismiss: true,
			canDismiss: true,
			cssClass: 'terms-of-use',
			componentProps: {
				isModal: true
			}

		};
		let modal = this.modalService.openModal(obj);
	}

	async privacyPopUp() {
		let obj = {
			component: PrivacyPolicyComponent,
			backdropDismiss: true,
			canDismiss: true,
			cssClass: 'privacy-policy',
			componentProps: {
				isModal: true
			}

		};
		let modal = this.modalService.openModal(obj);
	}

	ngOnDestroy() {
		this.commonService.localStorageRemove("becomeCustomer");
	}
}
