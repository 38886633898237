import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToasterService } from '../../services/toaster.service';
import { EnterpriseDetailComponent } from '../enterprise-detail/enterprise-detail.component';

// IMAGE FILE SIZE 40mb MAX
const MAX_FILE_SIZE_IMAGE = 5 * 1024 * 1024;

export interface MyProfile {
	first_name?: string;
	last_name?: string;
	platform?: string;
	email?: string;
	mobile_no?: number;
	company_address?: string;
	company_name?: string;
	file?: any;
}
@Component({
	selector: 'app-my-document',
	providers: [EnterpriseDetailComponent],
	templateUrl: './my-document.component.html',
	styleUrls: ['./my-document.component.scss'],
})

export class MyDocumentComponent implements OnInit, OnDestroy {

	public subscription = new Subscription();
	public isEnterpriced = false;
	isLoading: boolean = false;
	showMsg = false;
	toastRef;
	public fromPage: any;
	isDisabled = false;
	type = 'warning';
	messageData: any = {};

	showContent: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private toasterService: ToasterService,
		private dataService: ApiService,
		private platform: Platform,
		private navCtrl: NavController,
		public enterpriseData: EnterpriseDetailComponent,
		public getPlatformService: GetPlatformService

	) {

		this.showMsg = false;
		this.commonService.eventObservable.subscribe((data: any) => {
			this.messageData = {};
			this.showMsg = false;
			if (data.event == 'alertMessage' && data['data']['message']) {
				this.showMsg = true;
				this.messageData = data['data'];
				if (this.commonService.userData.isCustomer && this.commonService.userData.customer_type == "INDIVIDUAL") {
					this.messageData['type'] = 'success';
				}
			}
		})
		this.commonService.getUserDetail().then((data) => {
			if ((this.commonService.userData['isSeller'] && data['seller_approval_status'] == 'APPROVED') || (this.commonService.userData['isCustomer'] && data['approval_status'] == 'APPROVED')) {
				this.messageData['type'] = 'success';
			}
			else {
				this.messageData['type'] = 'warning';
			}
			if (data['show_message']) {
				if (data['seller_approval_status'] != 'REJECTED' || data['approval_status'] != 'REJECTED') {
					this.showMsg = true;
					this.messageData['message'] = data['message'];
				}
				if (this.commonService.userData.isCustomer && this.commonService.userData.customer_type == "INDIVIDUAL") {
					this.messageData['type'] = 'success';
				}
			}
		}, error => {
		});

	}


	showToast = () => {

	}
	ngOnInit() {
	}

	ionViewWillEnter() {

	}


	createEnterpriseProfile() {
		this.isEnterpriced = true;
		this.toasterService.warningToast('Your Request For Enterprise Customer Has Sent To Admin. You Will Be Notified Once Verification Completed.');
	}

	showToastWithCloseButton() {
		this.toasterService.successToast('Your Request Accepted, Start To Add The Product');

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
