<div class="summary" [ngClass]="orderSummaryList && orderSummaryList.length > 0  ? 'dark-box' : ''">
	<div class="fs-22 fw-600 title pv-15 title"> {{title}}</div>
	<div class="products-details">
		<div *ngFor="let item of orderSummaryList;let i = index" class="items">
			<div class="divider-line"></div>
			<div class="details mt-20 mb-20">
				<div class="item-img mr-15"><img alt="Image"
						[src]="item.default_image_url ? item.default_image_url : '/assets/product/product7.svg'">
				</div>
				<div class="product-desc">
					<div class="mb-10 fs-16 fw-500"> {{item.product_name}}</div>
					<div class="mb-10 fs-16 fw-500">{{item.price | number}} X <span
							class="fw-b">{{commonService.currencySymbol}}
							{{item.quantity}}</span>/{{item.unit_name}}
					</div>
					<ng-container>
						<div class="mb-5 pointer" (click)="openModal(item)">
							<span class="fs-14 fw-b" [ngClass]="item.isView ? 'blue-text' :''"> View Details</span>
							<img alt="View Icon" height="8" class="pl-5 mt-5 pointer"
								[src]="item.isView ? '/assets/icon/dropdown-blue-up.svg': '/assets/icon/dropdown-bottom.svg'">
						</div>
						<div class="fs-16 fw-500" *ngIf="item.isView">
							<div class="mb-5 sku fs-14"><span class="info-heading">SKU:</span> {{item.sku}}</div>
							<div class="mb-10 size fs-14" *ngFor="let oneAttr of item.product_attributes">
								<span class="info-heading"
									*ngIf="oneAttr.attribute_name &&  oneAttr.value">{{oneAttr.attribute_name}}:</span>
								{{oneAttr.value}}
							</div>
						</div>
					</ng-container>
					<div class="flex space-between">
						<div class="mb-10 fw-b">{{commonService.currencySymbol}} {{item.sub_total | number}}</div>
						<div class="right pointer" (click)="deleteOrderPopup(item.seller_product_id,i)">
							<img alt="Delete Icon" src="../../../assets/icon/delete.svg">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-container *ngIf="orderSummaryList.length > 0">
	<div class="item-total subtotal pt-20">
		<div class="fs-16 fw-500 left field">Items Subtotal<br><span class="fs-14"> (With Shipping Charge</span>
			<br><span class="fs-14"> & Excluding GST)</span>
		</div>
		<div class="fs-16 fw-500 right"> {{commonService.currencySymbol}} {{orderDetails.items_sub_total | number}}
		</div>

	</div>
	<div class="item-total gst">
		<div class="fs-16 left fw-500 field">IGST</div>
		<div class="fs-16 fw-500  right"> {{commonService.currencySymbol}} {{orderDetails.gst_total_amount |
			number}}</div>
	</div>
</ng-container>

<div class="total-summary pt-10">
	<div class="divider-line"></div>
	<div class="fs-16 left field mt-10">Items Subtotal <br><span class="fs-14"> (With Shipping Charge</span>
		<br><span class="fs-14"> & Excluding GST)</span>
	</div>
	<div class="fs-16 fw-b  blue-text right mt-10"> {{commonService.currencySymbol}}
		{{subTotal | number}}
	</div>
</div>