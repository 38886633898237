<ng-container *ngIf="showMsg">
	<div [ngClass]="messageData.type == 'warning' ? 'warnig-custom-text' : 'success-custom-text'">
		<img alt="Warning"
			[src]="messageData.type == 'warning' ? '/assets/icon/warning.svg' : '/assets/icon/white-checked.svg'">
		<span class="custom-notification">
			<a href="" *ngIf="messageData['link'] ; else noLink" [routerLink]="messageData.link">
				{{messageData.message}}
			</a>
			<ng-template #noLink>
				{{messageData.message}}
			</ng-template>
		</span>
	</div>
</ng-container>
<div class="my-profile-container p-50">
	<div class="profile-details">

		<div class="heading-label account-form">
			<div class="upload pb-15">
				<app-document [from]="'my-profile'"></app-document>
			</div>
		</div>

		<div class="form-data" *ngIf="isLoading">
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
		</div>
	</div>
</div>